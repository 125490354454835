import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import { propertyService } from "../../utils/propertyService";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import Header from "../global-components/header";
import jsonData from "./city.json"
import CallToActionV1 from "../section-components/call-to-action-v1";
import Footer from "../global-components/footer";
import Mapbox from '../section-components/mapbox';
import PageHeader from '../global-components/page-header';


class Table_SFH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProperties: [],
      pendingProperties: [],
      closedProperties: [],
      activeData: [],
      pendingData: [],
      closedData: [],
      redirect: false,
      url: "",
      filter: "",
      cityName: "",
      cityEndpoint: "",
      activeRent: "",
      activeSale: "",
      loadTableActive: "",
      loadTablePending: "",
      loadTableClosed: "",
      storesApi: [],
      exitProperties: false
    };
    this.state = {isToggleOn: true};
    this.state = {
      loadTableActive: true,
      loadTableClosed: true,
      loadTablePending: true
    }

  } 

  componentDidMount() {
    localStorage.setItem('url',window.location.href)
    let api = propertyService();
    let features = []
    let city = jsonData.filter(item => item.Endpoint == this.props.match.params.city)[0].Name;
    this.setState(
      {
      cityName: city,
      activeRent: false,
      activeSale: true
      }
    )
    //get active data
    api.getPropertiesSFH(city, "Active").then((data) => {
      this.setState({
        activeProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) != 0
              ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          address: item.address,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          lotSize: item.lotSize ? parseInt(item.lotSize).toLocaleString("en-US") : "N/A",
          waterFront: item.waterfrontFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          pool: item.poolFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          type: item.type
        })
        )
      });
      if( data !== undefined ){
      features = data.map((item) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: item.coordinates
          },
          properties: {
            price: item.listPrice,
            address: item.address,
            city: item.city,
            postalCode: item.postalCode,
            beds: item.beds,
            baths: item.baths,
            squareFeet: item.squareFeet,
            image: item.media[0],
            idList: item.id,
            icon: "Cat"
          }
        }))

        this.setState({
          storesApi : {
            type: "FeatureCollection",
            features: features
          }
        })

        this.setState({
          exitProperties: true
        })
        
        this.setState({
          center: features[0]?.geometry?.coordinates
        })
      };
      this.setState(
        {
          activeData: this.state.activeProperties.filter(row => row.type == "Residential"),
        }
      );
      this.setState({
        loadTableActive : false
      });
    });
    //get pending data
    api.getPropertiesSFH(city, "Pending").then((data) => {
      this.setState({
        pendingProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) != 0
              ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          address: item.address,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          lotSize: item.lotSize ? parseInt(item.lotSize).toLocaleString("en-US") : "N/A",
          waterFront: item.waterfrontFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          pool: item.poolFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          type: item.type
        })
        )
      });
      this.setState(
        {
          pendingData: this.state.pendingProperties.filter(row => row.type == "Residential"),
        }
      )
      this.setState({
        loadTablePending : false
      });
    });
    //get closed data
    api.getPropertiesSFH(city, "Closed").then((data) => {
      this.setState({
        closedProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100) != 0
              ? parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          address: item.address,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          salePrice: "$" + parseInt(item.closePrice).toLocaleString("en-US"),
          saleSqft: isFinite(parseFloat(item.closePrice / item.squareFeet)) ? "$" + parseFloat(item.closePrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          lotSize: item.lotSize ? parseInt(item.lotSize).toLocaleString("en-US") : "N/A",
          waterFront: item.waterfrontFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          pool: item.poolFeatures.length !== 0 ? "\u{2713}" : "\u{2715}",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          closeDate: item.closeDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$2/$3/$1'),
          type: item.type
        }))
      });

      this.setState(
        {
          closedData: this.state.closedProperties.filter(row => row.type == "Residential"),
        }
      )
      this.setState({
        loadTableClosed : false
      });
    });

  }

  handleChange = (filter) => {
    this.setState({ filter }, () => {
      let text = "";
      this.state.filter.forEach(element => {
        text = text + element.value;
      });
      switch (text) {
        case '':
          this.setState({
            activeData: this.state.activeProperties,
            pendingData: this.state.pendingProperties,
            closedData: this.state.closedProperties
          })
          break;
        case 'pool':
          this.setState({
            activeData: this.state.activeProperties.filter(row => row.pool == "\u{2713}"),
            pendingData: this.state.pendingProperties.filter(row => row.pool == "\u{2713}"),
            closedData: this.state.closedProperties.filter(row => row.pool == "\u{2713}")
          })
          break;
        case 'waterfront':
          this.setState({
            activeData: this.state.activeProperties.filter(row => row.waterFront == "\u{2713}"),
            pendingData: this.state.pendingProperties.filter(row => row.waterFront == "\u{2713}"),
            closedData: this.state.closedProperties.filter(row => row.waterFront == "\u{2713}")
          })
          break;
        case 'poolwaterfront':
          this.setState({
            activeData: this.state.activeProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}"),
            pendingData: this.state.pendingProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}"),
            closedData: this.state.closedProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}")
          })
          break;
        case 'waterfrontpool':
          this.setState({
            activeData: this.state.activeProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}"),
            pendingData: this.state.pendingProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}"),
            closedData: this.state.closedProperties.filter(row => row.pool == "\u{2713}" && row.waterFront == "\u{2713}")
          })
          break;
      }

    }
    );
  };

  getRentProperties = () => {
    this.setState({activeRent: true,activeSale: false});
    this.setState(
      {
        activeData: this.state.activeProperties?.filter(row => row.type == "Residential Lease"),
        pendingData: this.state.pendingProperties?.filter(row => row.type == "Residential Lease"),
        closedData: this.state.closedProperties?.filter(row => row.type == "Residential Lease")
      }
    )
  }

  getSaleProperties = () => {
    this.setState({activeRent: false,activeSale: true});
    this.setState(
      {
        activeData: this.state.activeProperties?.filter(row => row.type == "Residential"),
        pendingData: this.state.pendingProperties?.filter(row => row.type == "Residential"),
        closedData: this.state.closedProperties?.filter(row => row.type == "Residential")
      }
    )
  }

  goToPropertyDetail = row => {
    let url = `/product-details/${row.id}`;
    this.setState({
      redirect: true,
      url: url
    })
  }

  clickHandler = (state) => {
    let url = `/product-details/${state.target.id}`;
    this.setState({
      redirect: true,
      url: url
    })
  };


  render() {

    let classRent;
    let classSale;
    if(this.state.activeRent==true){
      classRent = "tab-rent-sale-active show tab-rent-sale";
      classSale = "show tab-rent-sale";
    }else if (this.state.activeSale == true){
      classRent = "show tab-rent-sale";
      classSale = "show tab-rent-sale tab-rent-sale-active";
    }

    const center = [-80.13243054906823,25.798891350759376];

    const options = [
      { value: 'pool', label: 'Pool' },
      { value: 'waterfront', label: 'Waterfront' },
    ]

    const columnsActivePending = [
      {
        name: '$ % Change',
        whiteSpace: 'normal',
        width: '110px',
        selector: row => row.perChange,
        conditionalCellStyles: [
          {
            when: row => String(row.perChange).includes("🠉") == true,
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => String(row.perChange).includes("🠋") == true,
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Address',
        selector: row => row.address,
      },
      {
        name: 'Listing Price',
        width: '120px',
        selector: row => row.listPrice,
      },
      {
        name: 'Sqft',
        width: '80px',
        selector: row => row.sqft,
      },
      {
        name: 'List $/Sqft',
        width: '110px',
        selector: row => row.listSqft,
      },
      {
        name: 'Lot Size',
        width: '80px',
        selector: row => row.lotSize,
      },
      {
        name: 'Waterfront',
        width: '110px',
        selector: row => row.waterFront,
        conditionalCellStyles: [
          {
            when: row => row.waterFront == "\u{2713}",
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => row.waterFront == "\u{2715}",
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Pool',
        width: '60px',
        selector: row => row.pool,
        conditionalCellStyles: [
          {
            when: row => row.pool == "\u{2713}",
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => row.pool == "\u{2715}",
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      }, {
        name: 'Beds',
        width: '60px',
        selector: row => row.beds,
      },
      {
        name: 'Baths',
        width: '70px',
        selector: row => row.baths,
      }, {
        name: 'Days On Market',
        width: '90px',
        height: 'auto',
        selector: row => row.daysOnMarket,
      }, {
        name: 'Type',
        selector: row => row.type,
        omit: true
      },
      {
        cell:(row) => <button className="detail-button-table" onClick={this.clickHandler} id={row.id} >Details</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    const columnsClosed = [
      {
        name: ' $ % Change',
        whiteSpace: 'normal',
        width: '105px',
        selector: row => row.perChange,
        conditionalCellStyles: [
          {
            when: row => String(row.perChange).includes("🠉") == true,
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => String(row.perChange).includes("🠋") == true,
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Address',
        width: '110px',
        selector: row => row.address,
      },
      {
        name: 'Listing Price',
        width: '110px',
        selector: row => row.listPrice,
      },
      {
        name: 'Sqft',
        width: '70px',
        selector: row => row.sqft,
      },
      {
        name: 'List $/Sqft',
        width: '95px',
        selector: row => row.listSqft,
      }, {
        name: 'Sales Price',
        width: '110px',
        selector: row => row.salePrice,
      },
      {
        name: 'Sales $/Sqft',
        width: '95px',
        selector: row => row.saleSqft,
      },
      {
        name: 'Lot Size',
        width: '80px',
        selector: row => row.lotSize,
      },
      {
        name: 'Waterfront',
        width: '105px',
        selector: row => row.waterFront,
        conditionalCellStyles: [
          {
            when: row => row.waterFront == "\u{2713}",
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => row.waterFront == "\u{2715}",
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Pool',
        width: '60px',
        selector: row => row.pool,
        conditionalCellStyles: [
          {
            when: row => row.pool == "\u{2713}",
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => row.pool == "\u{2715}",
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      }, {
        name: 'Beds',
        width: '60px',
        selector: row => row.beds,
      },
      {
        name: 'Baths',
        width: '65px',
        selector: row => row.baths,
      }, {
        name: 'Days On Market',
        width: '80px',
        height: 'auto',
        selector: row => row.daysOnMarket,
      }, {
        name: 'Closing Date',
        width: '100px',
        height: 'auto',
        selector: row => row.closeDate,
      },
      {
        name: 'Type',
        selector: row => row.type,
        omit: true
      },
      {
        cell:(row) => <button className="detail-button-table" onClick={this.clickHandler} id={row.id} >Details</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '72px',
          fontSize: '14px',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#e5eaee",
            overflow: 'hidden',
            whiteSpace: 'normal',
          }
          // override the row height
        },
      },
      headCells: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          paddingLeft: '4px',
          justifyContent: 'center',
          backgroundColor: '#f2f6f7',
          color: '#5c727d',
          overflow: 'hidden',
          whiteSpace: 'normal !important',
        },
      },
      cells: {
        style: {
          justifyContent: 'center',
          paddingLeft: '4px',
          overflow: 'hidden',
          whiteSpace: 'normal',

          // width: '7%'
        },
      },
      pagination: {
        style: {
          backgroundColor: '#f2f6f7',
          color: '#5c727d'
        }
      }
    };

    const colourStyles = {
      control: base => ({
          ...base,
          minHeight: 30,
          height: "50px",
          minWidth: "200px"
      }),
      dropdownIndicator: base => ({
          ...base,
          padding: 4
      }),
      clearIndicator: base => ({
          ...base,
          padding: 4
      }),
      multiValue: base => ({
          ...base,
          backgroundColor: "#e5eaee",
      }),
      valueContainer: base => ({
          ...base,
          padding: '0px 6px',
          height: "50px"
      }),
      input: base => ({
          ...base,
          margin: 0,
          padding: 0,
          height: "50px",
          fontSize: "10px"
      })
    };

    if (this.state.redirect) {
      return <Redirect to={this.state.url} />
    }

    let breadcrumbs = [];
    breadcrumbs.push({
      name: 'Single Family Homes',
      url: 'single-family-homes'
    })
    return (
      <div>
      <Header></Header>
      <PageHeader headertitle= {this.state.cityName} customclass="mb-0" breadcrumbs={breadcrumbs} />
      {/* <PageHeader headertitle="Condominium" customclass="mb-0" /> */}
      <div className={"ltn__apartments-plan-area pt-115--- pb-70 mt-30"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Single Family Homes
                </h6>
                <h1 className="section-title">{this.state.cityName}</h1>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_1"
                  >
                    Available
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_2">
                    Pending
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    Closed
                  </a>
                </div>
              </div>
              <div className="tab-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white--- p-4">
                      <div className="ltn__shop-options">
                        <ul>
                            <div className="ltn__car-dealer-form-tab">
                            <div className="ltn__tab-menu  text-uppercase">
                            <div className="nav">
                              <a className={classSale} data-bs-toggle="tab" href="#ltn__form_tab_1_1" onClick={this.getSaleProperties}><i className="fas fa-home" />For Sale</a>
                              <a data-bs-toggle="tab" onClick={this.getRentProperties} className={classRent}><i className="fas fa-home" />For Rent</a>
                            </div>
                            </div>
                            </div>
                            <Select 
                            
                              options={options}
                              isMulti
                              onChange={this.handleChange}
                              value={this.state.filter}
                              styles={colourStyles}
                              placeholder="Select a filter"
                              // theme = {theme}
                            > </Select>
                        </ul>
                      </div>
                      <div className="tab-pane fade active show" id="liton_tab_3_1">
                        <div className="ltn__apartments-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                                <DataTable columns={columnsActivePending}
                                  data={this.state.activeData}
                                  pagination
                                  onRowClicked={this.goToPropertyDetail}
                                  customStyles={customStyles}
                                  progressPending={this.state.loadTableActive}
                                  noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                                <DataTable columns={columnsActivePending}
                                  data={this.state.pendingData}
                                  pagination
                                  onRowClicked={this.goToPropertyDetail}
                                  customStyles={customStyles}
                                  progressPending={this.state.loadTablePending}
                                  noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_3">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <DataTable columns={columnsClosed}
                                data={this.state.closedData}
                                pagination
                                onRowClicked={this.goToPropertyDetail}
                                customStyles={customStyles}
                                progressPending={this.state.loadTableClosed}
                                noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        this.state.exitProperties === true
        ? <div className="ltn__banner-area go-top">
          <div className="container">
            <h4 className="title-2 mb-10">Map of properties</h4>
            <br></br>
            <Mapbox stores={this.state.storesApi} center={center}/>
            <br></br>
          </div>  
          </div>
        : ""
      }
      
      <CallToActionV1 />
      <Footer />
      </div>
    );
  }
}

export default Table_SFH;