import React, {useEffect,useState} from 'react';
import Navbar from '../global-components/navbar-v3';
import PageHeader from '../global-components/page-header';
import SliderPC from '../shop-components/slider-preconstruction';
import PcDetails from '../shop-components/pre-construction-details';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';
import data from '../pre-contruction/Json/elser.json';


const DetailsPreConstruction = ({match}) => { 
    const [property, setProperty] = useState([]);
    const preData = {
        elser: () => import("../pre-contruction/Json/elser.json"),
        mwc: () => import("../pre-contruction/Json/mwc.json"),
        aria: () => import("../pre-contruction/Json/aria.json"),
        casabella: () => import("../pre-contruction/Json/casabella.json"),
        residencesbrickell: () => import("../pre-contruction/Json/1428Brickell.json"),
        regisbrickell: () => import("../pre-contruction/Json/regisbrickell.json"),
        regissunny: () => import("../pre-contruction/Json/regissunny.json"),
        baianorth: () => import("../pre-contruction/Json/elser.json"),
        edition: () => import("../pre-contruction/Json/elser.json"),
        oasis: () => import("../pre-contruction/Json/oasis.json"),
        ocean: () => import("../pre-contruction/Json/elser.json"),
        piersixtysix: () => import("../pre-contruction/Json/piersixtysix.json")
      };

    useEffect(() => {
        preData[match.params.project]()
          .then(data => setProperty(data)
          )
          .catch(_ => console.log("res", _));
      }, []);

    let Amenities = property.Amenities;
    let images = property.Images;
    let Features = property.Features;
    let Location = property.Location;
    let Floorplans = property.floorplans;
    let video = property.video;
    let videoPath = property.videopath;
    let BannerUrl = property.Banner;
    let sqft = property.Sqft;
    return <div>
        <PcDetails 
        name= {property.Name}
        Tag= {property.PropType}
        City={property.City}
        Address={property.Address}
        Location = {Location}
        CompAddres={property.CompAddres}
        Description={property.Description}
        PriceInit={property.PriceInit}
        PropType = {property.PropType}
        Bedrooms ={property.Bedrooms}
        Bathrooms ={property.Bathrooms}
        YearBuild= {property.YearBuild}
        Sqft={sqft}
        Units = {property.Units}
        Amenities = {Amenities}
        Features = {Features}
        Imgs = {images}
        video ={video}
        Floorplans = {Floorplans}
        Banner = {BannerUrl}
        videopath={videoPath}
        />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default DetailsPreConstruction

