// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';


mapboxgl.accessToken = 'pk.eyJ1IjoiYmxva2hhdXNyZSIsImEiOiJjbDNlaHVudHEwZTVyM3FwNmJzdGx1N2JkIn0.m38iCuGtMHLErbdXhPZacg';


class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
          lng: -70.9,
          lat: 42.35,
          zoom: 9,
          numStore: 0
        };
        this.mapContainer = React.createRef();
    }

      componentDidMount() {
        this.setState({numStore : this.props.stores.features.length})
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: this.props.center,
            zoom: 12,
            pitch: 45,
            bearing: -17.6,
            antialias: true
            });

        if(this.props.stores !== undefined){
          this.props.stores.features.forEach(function (store, i) {
            store.properties.id = i;
          });
        }
          map.on('style.load', () => {
            // Insert the layer beneath any symbol layer.
            const layers = map.getStyle().layers;
            const labelLayerId = layers.find(
            (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;
             
            map.resize();
            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetMap.
            map.addLayer(
            {
            'id': 'add-3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': 15,
            'paint': {
            'fill-extrusion-color': '#aaa',
             
            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
            ],
            'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
            }
            },
            labelLayerId
            );
            });
        map.on('load', () => {
            /* Add the data to your map as a layer */
            map.addSource('places', {
                'type': 'geojson',
                'data': this.props.stores
              });
              if(this.props.stores !== null){
                if(this.props.stores.features.length == 1){
                  addMarkers(this.props.stores);
                  flyToStore(this.props.stores.features[0])
                } else {
                  buildLocationList(this.props.stores);
                  addMarkers(this.props.stores);
                }
              } 
          });

          function addMarkers(stores) {
            /* For each feature in the GeoJSON object above: */
            for (const marker of stores.features) {
              /* Create a div element for the marker. */
              const el = document.createElement('div');
              /* Assign a unique `id` to the marker. */
              el.id = `marker-${marker.properties.id}`;
              /* Assign the `marker` class to each marker for styling. */
              el.className = 'marker';
              /**
               * Create a marker using the div element
               * defined above and add it to the map.
               **/
              new mapboxgl.Marker(el, { offset: [0, -23] })
                .setLngLat(marker.geometry.coordinates)
                .addTo(map);
    
              /**
               * Listen to the element and when it is clicked, do three things:
               * 1. Fly to the point
               * 2. Close all other popups and display popup for clicked store
               * 3. Highlight listing in sidebar (and remove highlight for all other listings)
               **/
              el.addEventListener('click', (e) => {
                /* Fly to the point */
                flyToStore(marker);
                /* Close all other popups and display popup for clicked store */
                createPopUp(marker);
                /* Highlight listing in sidebar */
                const activeItem = document.getElementsByClassName('active');
                e.stopPropagation();
                if (activeItem[0]) {
                  activeItem[0].classList.remove('active');
                }
                if(stores.features.length > 1 ){
                  const listing = document.getElementById(
                    `listing-${marker.properties.id}`
                  );
                  console.log()
                  listing.classList.add('active');
                }
              });
            }
          }

          function buildLocationList(stores) {
            for (const store of stores.features) {
              /* Add a new listing section to the sidebar. */
              const listings = document.getElementById('listings');
              const listing = listings.appendChild(document.createElement('div'));
              /* Assign a unique `id` to the listing. */
              listing.id = `listing-${store.properties.id}`;
              /* Assign the `item` class to each listing for styling. */
              listing.className = 'item';
    
              /* Add the link to the individual listing created above. */
              const link = listing.appendChild(document.createElement('a'));
              link.className = 'title';
              link.id = `link-${store.properties.id}`;
              link.innerHTML = `${store.properties.address}`;
    
              /* Add details to the individual listing. */
              const details = listing.appendChild(document.createElement('div'));
              if(store.properties.squareFeet){
                details.innerHTML = `${store.properties.squareFeet.toLocaleString("en-US")} square feet`;
              } else details.innerHTML = `N/A`;
              
              if (store.properties.price) {
                details.innerHTML += ` &middot; $${store.properties.price.toLocaleString("en-US")}`;
              } else details.innerHTML += ` &middot; N/A`
    
              /**
               * Listen to the element and when it is clicked, do four things:
               * 1. Update the `currentFeature` to the store associated with the clicked link
               * 2. Fly to the point
               * 3. Close all other popups and display popup for clicked store
               * 4. Highlight listing in sidebar (and remove highlight for all other listings)
               **/
              link.addEventListener('click', function () {
                for (const feature of stores.features) {
                  if (this.id === `link-${feature.properties.id}`) {
                    flyToStore(feature);
                    createPopUp(feature);
                  }
                }
                const activeItem = document.getElementsByClassName('active');
                if (activeItem[0]) {
                  activeItem[0].classList.remove('active');
                }
                this.parentNode.classList.add('active');
              });
            }
          }

          function flyToStore(currentFeature) {
            map.flyTo({
              center: currentFeature.geometry.coordinates,
              zoom: 15
            });
          }
          
          function createPopUp(currentFeature) {
            const popUps = document.getElementsByClassName('mapboxgl-popup');
            /** Check if there is already a popup on the map and if so, remove it */
            if (popUps[0]) popUps[0].remove();
          
            const popup = new mapboxgl.Popup({ closeOnClick: false })
              .setLngLat(currentFeature.geometry.coordinates)
              .setHTML(`<div class="p-0 container">
                          <a href=${"/#/product-details/"+currentFeature.properties.idList}><img src=${currentFeature.properties.image} alt="Property Image" /></a>
                          <div class="map-popup--title">
                            <h6>$ ${currentFeature.properties.price.toLocaleString("en-US")}</h6>
                            <a href=${"/#/product-details/"+currentFeature.properties.idList}><h6>${currentFeature.properties.address}</h6></a>
                          </div>
                          <div class="row map-popup--detail align-items-center justify-content-center ">
                              <div class="col-sm popup--detail-item item-border">
                              <span> ${currentFeature.properties.beds} <i class="flaticon-bed"></i> <p>Bedrooms</p> </span>
                              </div>
                              <div class="col-sm popup--detail-item">
                              <span>${currentFeature.properties.baths} <i class="flaticon-bathtub"></i> <p>Bathrooms</p></span>
                              </div>   
                          </div>
                        </div>
              `)
              .addTo(map);
          }
        }

        render() {
            return (
            <div className="mapbox-body col-lg-12">
              {
                this.state.numStore == 1
                ? ""
                : <div className="mapbox-sidebar">
                    <div className='heading'>
                      <h1>Our locations</h1>
                    </div>
                    <div id='listings' className='listings'></div>
                  </div>
              }
                
            <div ref={this.mapContainer} className={this.state.numStore != 1 ? "mapbox-container" : "mapbox-container--single-property"} />
            </div>
            );
        }
}

export default Map