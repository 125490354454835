import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamHome extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-115 pb-90 go-top">
				<div className="container">
				<div className="row">
                <div className="col-lg-12">
                    <div className="ltn__team-details-member-info text-center mb-40">
                        <div className="team-details-img">
                        <img src={publicUrl+"assets/img/sidebar/Juan-alvarez-1.jpg"} alt="Team Member Image" />
                        </div>
                        <h2>Juan M. Alvarez</h2>
                        <h6 className="text-uppercase ltn__secondary-color">MSIRE – MSF | Broker – Principal</h6>
                        <div className="ltn__social-media-3">
                        <ul>
                            <li><a href="https://www.facebook.com/BlokHausRE/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="mailto: sales@blokhausre.com" title="Email"><i className="fa fa-envelope" /></a></li>
                            <li><a href="https://www.linkedin.com/in/juan-alvarez-msire-msf-58310746/?original_referer=https%3A%2F%2Fblokhausre.com%2F" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                        </ul>
                        </div>
                    </div>
                    <hr />
                    </div>

				</div>
				{/*
				<div className="row justify-content-center">
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/4.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Rosalina D. William</Link></h4>
						<h6 className="ltn__secondary-color">Real Estate Broker</h6>
						<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-twitter" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/2.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Kelian Anderson</Link></h4>
						<h6 className="ltn__secondary-color">Selling Agents</h6>
						<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-twitter" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/5.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Miranda H. Halim</Link></h4>
						<h6 className="ltn__secondary-color">Property Seller</h6>
						<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-twitter" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
				</div>
				*/}
				</div>
			</div>
        }
}

export default TeamHome