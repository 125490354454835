import React from 'react';
import Header from './global-components/header';
import PreConstructionGrid from './pre-contruction/pre-construction-grid';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import CallToActonPreconst from './pre-contruction/calltoaction';

const PreConstruction = () => {
    return <div>
        <Header />
        <CallToActonPreconst/>
        <PreConstructionGrid />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default PreConstruction

