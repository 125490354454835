import React from 'react';
import Header from '../global-components/header';
import PropertiesGrid from './featured-grid';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';

const PreConstruction = () => {
    return <div>
        <Header />
        <PropertiesGrid />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default PreConstruction

