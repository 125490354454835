import React from 'react';
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v3';
import AboutV3 from './section-components/about-v3';
import VideoV1 from './section-components/video-v1';
import Neighborhoods from './section-components/neighborhoods';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Newsletter from './section-components/newsletter';
import FeaturedProperties from './section-components/property_carrousel';


const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Neighborhoods />
        <Newsletter/>
        <AboutV3 />
        <FeaturedProperties />
        <VideoV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V1

