import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BannerV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
		let images = this.props.images
		let type = this.props.type
		let Description = this.props.Description
		let Location = this.props.Location
		let buttonText = this.props.buttonText

    return  <div className="ltn__slider-area ltn__slider-3  section-bg-2">
			  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
			    {/* ltn__slide-item */}
			    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl+"assets/img/slider/StRegisSunnyIsles.png"}>
			      <div className="ltn__slide-item-inner text-center">
			        <div className="container">
			          <div className="row">
			            <div className="col-lg-12 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  <div className="slide-video mb-50 d-none">
			                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                      <i className="fa fa-play" />
			                    </a>
			                  </div>
			                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Luxury Pre Construction</h6>
			                  <h1 className="slide-title animated ">St Regis <br /> Sunny Isles</h1>
			                  <div className="slide-brief animated">
			                    <p>Exclusive, ultra luxurious and only residential oceanfront towers designed by Arquitectonica are coming soon to the famous Sunny Isles Beach, Florida.</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                    <Link to="/pre-construction/st-sunny-isles" className="theme-btn-1 btn btn-effect-1">St Regis.</Link>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/* ltn__slide-item */}
			    <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl+"assets/img/slider/Elser.png"}>
			      <div className="ltn__slide-item-inner  text-right text-end">
			        <div className="container">
			          <div className="row">
			            <div className="col-lg-12 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Luxury Pre Construction</h6>
			                  <h1 className="slide-title animated ">The Elser <br />Downtown</h1>
			                  <div className="slide-brief animated">
			                    <p>Hotel and Residences shares the same bustling locale and glimmering views of Biscayne Bay previously enjoyed in the early 1900’s at Elser Pier.</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                    <Link to="/pre-construction/elser" className="theme-btn-1 btn btn-effect-1">Visit Elser</Link>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/* ltn__slide-item */}
			    <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl+"assets/img/slider/AriaReserve.png"}>
			      <div className="ltn__slide-item-inner  text-left">
			        <div className="container">
			          <div className="row">
			            <div className="col-lg-12 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Luxury Pre Construction</h6>
			                  <h1 className="slide-title animated ">Aria <br/> Reserve</h1>
			                  <div className="slide-brief animated">
			                    <p>A captivating bayfront location, elegant architecture and luxurious amenities. It brings the tallest waterfront, residential twin towers in the United States.</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                    <Link to="/pre-construction/aria" className="theme-btn-1 btn btn-effect-1">Make a tour</Link>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/*  */}
			  </div>
			</div>

        }
}

export default BannerV3