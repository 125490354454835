import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget">
				            <div className="footer-logo">
				              <div className="site-logo">
				                <img src={publicUrl+"assets/img/blokhaus_side_white.png"} alt="Logo" />
				              </div>
				            </div>
				            <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p>1680 Michigan Avenue Suite PH-5 Miami Beach, Florida 33139</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+1 (786) 536-7328">+1 (786) 536-7328</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:info@blokhausre.com">info@blokhausre.com</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				            <div className="ltn__social-media mt-20">
						    	<Social />
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Company</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/about-juan">About Juan Alvarez</Link></li>
				                <li><Link to="/blog-grid">Blog</Link></li>
				                <li><Link to="/about">Blokhaus</Link></li>
				                <li><Link to="/contact">Contact us</Link></li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Site Map</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/pre-construction">Pre Construction</Link></li>
				                <li><Link to="/featured-properties">Featured Properties</Link></li>
				                <li><Link to="/single-family-homes">Single Family Homes</Link></li>
				                <li><Link to="/condos">Condos</Link></li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Location</h4>
				            <div className="footer-menu go-top">
							<div className="property-details-google-map mb-60">
							<iframe src= {"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80161.49884076053!2d-80.18629395210328!3d25.787044809799774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b570987eaa39%3A0x7e320f18ec81eda7!2sBlokHaus%20Real%20Estate%20%2B%20Investments!5e0!3m2!1sen!2sco!4v1671045725730!5m2!1sen!2sco"} width="100%" height="100%" allowFullScreen aria-hidden="false" tabIndex={0} />
						</div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				  <Copyright />
				</footer>
        )
    }
}


export default Footer_v1