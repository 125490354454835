import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Newsletter extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-50 pb-50 ">
			    <div className="row">
                <div className="widget ltn__search-widget ltn__newsletter-widget">                            
                    <h6 className="ltn__widget-sub-title">// Subscribe</h6>
                    <h4 className="ltn__widget-title">Get Our Newsletter</h4>
                    <form  method="get" className="ltn__form-box">
                            <input type="email" name="email" placeholder="Your E-mail..." />
                            <button type="submit" className="btn theme-btn-1 btn-effect-1">SUBMIT</button>
                        </form>
                    <div className="ltn__newsletter-bg-icon">
                        <i className="fas fa-envelope-open-text" />
                    </div>
                    </div>
			    </div>
			</div>
        }
}

export default Newsletter
