import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Gallery from '../section-components/gallery-v1';
import Mapbox from '../section-components/mapbox';

class ShopDetails extends Component {

	constructor(props) {
		super(props);
		this.state = {
			similarProperties : [],
		}
	}


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/';
		const center = [-80.13243054906823,25.798891350759376];
		let images = this.props.property.media;
		let amenities = this.props.property.appliances?.concat(
			this.props.property.associationAmenities,
			this.props.property.exteriorFeatures,
			this.props.property.interiorFeatures,
			this.props.property.securityFeatures,
			this.props.property.waterfrontFeatures,
			this.props.property.view,
			this.props.property.laundryFeatures,
		)
		
		if(this.props.property.viewYN){ amenities.push("Has View")}
		if(this.props.property.waterfrontYN){ amenities.push("Has Waterfront")}
		if(this.props.property.poolYN){ amenities.push("Has Pool")}
		if(this.props.property.heatingYN){ amenities.push("Has Heating System")}
		if(this.props.property.coolingYN){ amenities.push("Has Cooling System")}
		if(this.props.property.garageYN){ amenities.push("Has Garage")}

		let features = [];
		let store;
		if(this.props.property){
			features.push({
				type: "Feature",
						geometry: {
							type: "Point",
							coordinates: this.props.property.coordinates
						},
						properties: {
							price: this.props.property.listPrice,
							address: this.props.property.address,
							city: this.props.property.city,
							postalCode: this.props.property.postalCode,
							beds: this.props.property.beds,
							baths: this.props.property.baths,
							squareFeet: this.props.property.squareFeet,
							image: this.props.property.media ? this.props.property.media[0] : "",
							idList: this.props.property.id
						}
			});
			
				store = {
					type: "FeatureCollection",
					features: features
				}
			
		}

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-category">
							<Link className="bg-orange" to="#">{this.props.property.subType}</Link>
							</li>
							<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />{this.props.property.onMarketDay?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$2/$3/$1')}
							</li>
							{/* <li>
							<Link to="#"><i className="far fa-comments" />35 Comments</Link>
							</li> */}
						</ul>
						</div>
						<h1>{this.props.property.address+", "+this.props.property.city}</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span>{this.props.property.city+", "+this.props.property.countyOrParish}</label>
						<h4 className="title-2">Description</h4>
						<p>{this.props.property.description}</p>
						{/* <p>To the left is the modern kitchen with central island, leading through to the unique breakfast family room which feature glass walls and doors out onto the garden and access to the separate utility room.</p> */}
						<h4 className="title-2">Property Detail</h4>  
						<div className="property-detail-info-list section-bg-1 clearfix mb-60">                          
						<ul>
							<li><label>Property ID:</label> <span>{this.props.property.listingId}</span></li>
							<li><label>Price: </label> <span className="highlighted-word">{parseInt(this.props.property.listPrice).toLocaleString("en-US")}$</span></li>
							<li><label>Status:</label> <span>{this.props.property.status}</span></li>
							<li><label>Property Type:</label> <span>{this.props.property.type}</span></li>
							<li><label>Year built:</label> <span>{this.props.property.yearBuilt}</span></li>
						</ul>
						<ul>
							<li><label>Address:</label> <span>{this.props.property.streetName} </span></li>
							<li><label>City:</label> <span>{this.props.property.city}</span></li>
							<li><label>Area:</label> <span>{
								// first letter in mayus
								this.props.property.buildName?.toLowerCase().split(" ").map((word) => { 
									return word[0].toUpperCase() + word.substring(1); 
								}).join(" ")
								}</span></li>
							<li><label>State/county:</label> <span>{this.props.property.countyOrParish}</span></li>
							<li><label>Zip/Postal Code:</label> <span>{this.props.property.postalCode}</span></li>
						</ul>
						</div>
						<h4 className="title-2">Facts and Features</h4>
						<div className="property-detail-feature-list clearfix mb-45">                            
						<ul>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-measure" />
								<div>
								<h6>Square feet</h6>
								<small>{this.props.property.squareFeet}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-garage-1" />
								<div>
								<h6>Garage</h6>
								<small>{this.props.property.garages}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Bedroom</h6>
								<small>{this.props.property.beds}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-swimming" />
								<div>
								<h6>Pool</h6>
								<small>{!this.props.property.poolFeatures?.length===0 ?"Yes" : "No"}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-bathtub" />
								<div>
								<h6>Bathroom</h6>
								<small>{this.props.property.baths}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-parking" />
								<div>
								<h6>Parking</h6>
								<small>{!this.props.property.parkingFeatures?.length===0 ?"Yes" : "No"}</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-dryer" />
								<div>
								<h6>Laundry</h6>
								<small>{!this.props.property.laundryFeatures?.length===0 ?"Yes" : "No"}</small>
								</div>
							</div>
							</li>
						</ul>
						</div>
						<h4 className="title-2 mb-10">Amenities</h4>
						<div className="property-details-amenities mb-60">
						<div className="row">
							<div className="col-lg-12 col-md-6">
							<div className="ltn__menu-widget">
								<ul className="amenities">
								{
									
									amenities?.filter(	word => word!=="Other").map((item)=>(
										<li key={item}>
			              					<i className="icon-done amenities-icon" />
			              					{item}
			            				</li>
									))
								}
								</ul>
							</div>
							</div>
						</div>
						</div>
						{
							this.props.property
							? <div >
								<h4 className="title-2">Map of properties</h4>
								<div className="property-details-google-map mb-60">
								
								<br></br>
								<Mapbox stores={store} center={center}/>
								<br></br>
								</div>  
							</div>
							: ""
						}
						{/* <div className="property-details-google-map mb-60">
							<iframe src={urlConstruct} width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
						</div> */}
						{/*  gallerry */}
						<h4 className="title-2">From Our Gallery</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<Gallery media={images}></Gallery>
						</div>
						</div>
						<p className="property-caption">The MLS data provided for the property above MLS#{this.props.property.listingId} is provided courtesy of {this.props.property.officeId}. Source: Southeast Florida MLS. Data updated {this.props.property.dateUpdated?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$2/$3/$1')}. Juan M. Alvarez above is a licensed Real Estate Broker with Blokhaus Real Estate + Investments as a cooperating broker or cooperating agent, and Juan M. Alvarez may not be the listing agent for this property. Information is deemed reliable but not guaranteed. The listing data on this page comes from the Internet Data Exchange (IDX), a collaboration between the REALTOR® associations in the South Florida Multiple Listing Service (MLS) including The Miami Association of Realtors and the Realtor Association of Greater Ft. Lauderdale. All rights reserved. A property appearing on this website is not necessarily an exclusive listing of Blokhaus Real Estate + Investments, and may be listed with other brokers. An agent of Blokhaus Real Estate + Investments appearing on this website as the contact person for any property is not necessarily the listing agent, however, is acting at a transaction broker. The name of the listing broker appears with every property detail page, shown just after the map. When properties are presented as part of lists, generally the Blokhaus Real Estate + Investments listings for Miami Beach are highlighted in yellow. The information being provided is for consumers' personal, non-commercial use. Federal law prohibits discrimination on the basis of race, color, religion, sex, handicap, familial status or national origin in the sale, rental or financing of housing. Contact Juan M. Alvarez on Blokhaus Real Estate + Investments for more information. Juan is a licensed Real Estate Broker and president of Blokhaus Real Estate + Investments.</p>
						{/* <h4 className="title-2">Related Properties</h4>
						<div className="row">
							{
								similarProperties?.map((item) => (
									<div className="col-xl-6 col-sm-6 col-12 go-top">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to={"/product-details/"+item.id}><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
											<div className="real-estate-agent">
											<div className="agent-img">
												<Link to={"/product-details/"+item.id}><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
											</div>
											</div>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">{item.type}</li>
											</ul>
											</div>
											<h2 className="product-title"><Link to={"/product-details/"+item.id}>{item.address}</Link></h2>
											<div className="product-img-location">
											<ul>
												<li>
												<Link to={"/product-details/"+item.id}><i className="flaticon-pin" /> {item.city}</Link>
												</li>
											</ul>
											</div>
											<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>{item.beds} </span>
												Bedrooms
											</li>
											<li><span>{item.bads}</span>
												Bathrooms
											</li>
											<li><span>{item.squareFeet} </span>
												square Ft
											</li>
											</ul>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<Link to="/shop" title="Compare">
													<i className="flaticon-add" />
												</Link>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<span>{item.listPrice}</span>
											</div>
										</div>
										</div>
									</div>
								))
							}

						</div> */}
					</div>
					</div>
					<div className="col-lg-4">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Author Widget */}
						<div className="widget ltn__author-widget">
						<div className="ltn__author-widget-inner text-center">
							<img src={publicUrl+"assets/img/sidebar/Juan-alvarez-1.jpg"} alt="Image" />
							<h5>Juan Alvarez</h5>
							<small>MSIRE – MSF - Broker Principal</small>
							<p>Real Estate Specialist </p>
							<div className="ltn__social-media">
							<ul>
								<li><a href="https://api.whatsapp.com/send/?phone=13057106804&text&type=phone_number&app_absent=0" title="Whatsapp"><i className="fab fa-whatsapp" /></a></li>
								<li><a href="https://www.linkedin.com/in/juan-alvarez-msire-msf-58310746/?original_referer=https%3A%2F%2Fblokhausre.com%2F" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
								<li><a href="https://www.instagram.com/juanmanueleto/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
							</div>
						</div>
						</div>
						{/* Form Widget */}
						<div className="widget ltn__form-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Message For Book</h4>
						<form action="#">
							<input type="text" name="yourname" placeholder="Your Name*" />
							<input type="text" name="youremail" placeholder="Your e-Mail*" />
							<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
							<button type="submit" className="btn theme-btn-1">Send Message</button>
						</form>
						</div>

						{/* Menu Widget (Category) */}
						<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Categories</h4>
						<ul className="go-top">
							<li><Link to="/condos">Condominium <span></span></Link></li>
							<li><Link to="/featured">Featured Properties <span></span></Link></li>
							<li><Link to="/pre-construction">Pre Construction  <span></span></Link></li>
							<li><Link to="/single-family-homes">Single Family Homes <span></span></Link></li>
						</ul>
						</div>
						{/* Popular Product Widget */}
						{/* <div className="widget ltn__popular-product-widget">       
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Featured Properties</h4>                     
						<div className="row ltn__popular-product-widget-active slick-arrow-1">
							{
								relatedProperties.map((item)=> (
									<div className="col-12">
									<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img go-top">
										<Link to="/shop"><img src={publicUrl+"assets/img/product-3/6.jpg"} alt="#" /></Link>
										<div className="real-estate-agent">
											<div className="agent-img">
											<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
											</div>
										</div>
										</div>
										<div className="product-info">
										<div className="product-price">
											<span>{item.listPrice}</span>
										</div>
										<h2 className="product-title"><Link to={"product-detail/"+item.id}>{item.address}</Link></h2>
										<div className="product-img-location">
											<ul>
											<li>
												<Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
											</li>
											</ul>
										</div>
										<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>3 </span>
											Bedrooms
											</li>
											<li><span>2 </span>
											Bathrooms
											</li>
											<li><span>3450 </span>
											square Ft
											</li>
										</ul>
										</div>
									</div>
									</div>
								))		
							} */}
{/* 						
						</div>
						</div> */}
						{/* Popular Post Widget 
						<div className="widget ltn__popular-post-widget go-top">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Leatest Blogs</h4>
						<ul>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/5.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/6.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/7.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/8.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
						</ul>
						</div>*/}
						{/* Social Media Widget */}
						<div className="widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
						<div className="ltn__social-media-2">
							<ul>
							<li><a href="https://www.facebook.com/BlokHausRE/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="https://mobile.twitter.com/blokhausre" title="Twitter"><i className="fab fa-twitter" /></a></li>
							<li><a href="https://co.linkedin.com/company/blokhausre" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="https://www.instagram.com/BlokHausRE/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
						</div>
						{/* Banner Widget */}
						<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
						</div>
					</aside>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ShopDetails