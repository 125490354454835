import React, { Component } from "react";
import Header from '../global-components/header';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';
import jsonData from './condos.json'
import { Link} from "react-router-dom";
import CallToAction from '../condos/calltoaction';
import { propertyService } from "../../utils/propertyService";
import Mapbox from '../section-components/mapbox';

class Landing_Condo extends Component {

	constructor(props){
		super(props);
		this.state = {
			stores: [],
			center: [-80.13243054906823,25.798891350759376],
			exitsProperties: false
		};
	}

	componentDidMount(){
		let api = propertyService();
		let filter = `PropertySubType eq 'Condominium' and MlsStatus eq 'Active'` ;
		let order = 'ListPrice desc';
		let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
            $orderby: order
        }
		let features = [];
		api.getProperties(params)
		.then((data) => {
				if(data !== undefined){
					features = data.filter(item => item.coordinates !== null).map((item)=> ({
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: item.coordinates
						},
						properties: {
							price: item.listPrice,
							address: item.address,
							city: item.city,
							postalCode: item.postalCode,
							beds: item.beds,
							baths: item.baths,
							squareFeet: item.squareFeet,
							image: item.media[0],
							idList: item.id,
						}
					}))
					this.setState({
						stores : {
							type: "FeatureCollection",
							features: features
						}
					})
					this.setState({
						exitsProperties: true
					})
				}
			}
			
		)
	}

	render(){
    
		let publicUrl = process.env.PUBLIC_URL+'/'

		return <div>
			<Header />
			<CallToAction/>
			<div className="ltn__banner-area pt-50 go-top">
					<div className="container">
					<div className="row">
						<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h2 className="">Browse Properties By Neighborhoods & Buildings</h2>
						</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/BalHarbour1.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/bal-harbour">Bal Harbour </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Brickell.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/brickell">Brickell  </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/BrickellKey.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/brickell-key">Brickell Key </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/CoconutGrove.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/coconut-grove">Coconut Grove </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Downtown.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/downtown"> Downtown</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Miamibeach.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/edgewater"> Edgewater</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/SunnyIsles.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/hollywood">Hollywood  </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/FisherIsland.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/fisher-island">Fisher Island  </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Hallandale.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/hallandale">Hallandale </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Miamibeach.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/miami-beach">  Miami Beach </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/SunnyIsles.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/sunny-isles"> Sunny Isles </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Surfside.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/condo/surfside"> Surfside </Link></h3>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
				{
						this.state.exitsProperties === true
						? <div className="ltn__banner-area pt-20 go-top">
							<div className="container">
							<h4 className="title-2 mb-10">Map of properties</h4>
							<br></br>
							<Mapbox stores={this.state.stores} center={this.state.center}/>
							<br></br>
							</div>  
						</div>
						: ""
				}
				<div className="ltn__banner-area pt-20 go-top">
					<div className="container">
					<h4 className="title-2 mb-10">Directory of Neighborhoods</h4>
							<div className="property-details-amenities mb-60">
							<div className="row">
								<div className="col-lg-12 col-md-6">
								<div className="ltn__menu-widget">
									<ul className="amenities">
										{
											jsonData.map((city)=>(
												<li className='go-neighborhoods' key={city.Endpoint}>
													<Link to={"/condo/"+city.Endpoint}>{city.City}</Link>
												</li>
											))
										}
									</ul>
								</div>
								</div>
							</div>
							</div>  
					</div>  
				</div>
			<CallToActionV1 />
			<Footer />
		</div>
	}
}

export default Landing_Condo