import React, { Component } from 'react';
import Header from '../global-components/header';
import PageHeader from '../global-components/page-header';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';
import jsonData from './condos.json'
import { Link} from "react-router-dom";
import { propertyService } from "../../utils/propertyService";
import Mapbox from '../section-components/mapbox';

class Condo_List extends Component {

	constructor(props) {
		super(props);
		this.state = {
			condos: [],
			stores: {
				type: "FeatureCollection",
				features: []
			},
			center: [-80.13243054906823,25.798891350759376],
			exitsProperties: false
		}
	}

	componentDidMount() {
		let api = propertyService();
		let condosTest= jsonData.filter(item => item.Endpoint === this.props.match.params.city)[0];
		let features = [];
		let TotalFeatures = [];
		let streets = [];
		let condosImage = {
			City: condosTest.City,
			Condos: []
		};
		condosTest.Condos.map((condo)=> {
			api.getImageCondo(condo.StreetName, condo.StreetNumber)
			.then((data)=> {
				if(data !== undefined){
					if( data.length !== 0){
						features.push(data.filter(item => item.coordinates !== null).map((item)=> ({
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: item.coordinates
							},
							properties: {
								price: item.listPrice,
								address: item.address,
								city: item.city,
								postalCode: item.postalCode,
								beds: item.beds,
								baths: item.baths,
								squareFeet: item.squareFeet,
								image: item.media[0],
								idList: item.id,
							}
						})))
						condosImage.Condos.push({
							Name: condo.Name,
							Endpoint: condo.Endpoint,
							Image: data[0].media[0]	
						})
						}
						this.setState({
							condos: condosImage
						})
					}
					
					TotalFeatures = features.flat();
					this.setState({
						stores : {
							type: "FeatureCollection",
							features: TotalFeatures.slice(0,200)
						}
					})
					this.setState({
						exitsProperties: true
					})
				})
			})
		
			
		}

	render() {

		let publicUrl = process.env.PUBLIC_URL+'/'
		let breadcrumb = [];
		breadcrumb.push({
			name: 'Condos',
			url: 'condos'
		})
		// let condosTest= [];
		// condosTest= jsonData.filter(item => item.Endpoint === this.props.match.params.city)[0]
		return <div>
        <Header />
        <PageHeader headertitle= {this.state.condos.City} customclass="mb-0" breadcrumbs={breadcrumb}/>
        <div className="ltn__banner-area pt-120 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>
						<h1 className="section-title">Condos in {this.state.condos.City}</h1>
					</div>
					</div>
				</div>
				<div className="row">
                        {
						 	this.state.condos.Condos?.map((condos)=>(
								<div className="col-lg-4 col-md-6" key={condos.Endpoint}>
								<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" style={{backgroundImage:`url(${condos.Image})`}} >                        
									<div className="ltn__banner-info">
									<h3><Link to={"/condo-detail/"+condos.Endpoint}>{condos.Name}</Link></h3>
									</div>
								</div>
								</div>
							))
						}
				</div>
				</div>
			</div>
            <div className="ltn__banner-area pt-20 go-top">
                <div className="container">
                <h4 className="title-2 mb-10">Directory of {this.state.condos.City}</h4>
						<div className="property-details-amenities mb-60">
						<div className="row">
							<div className="col-lg-12 col-md-6">
							<div className="ltn__menu-widget">
								<ul className="amenities">
                                    {
                                        this.state.condos.Condos?.map((condo)=>(
                                            <li className='go-neighborhoods' key={condo.Endpoint}>
                                                <Link to={"/condo-detail/"+condo.Endpoint}>{condo.Name}</Link>
                                            </li>
                                        ))
                                    }
								</ul>
							</div>
							</div>
						</div>
						</div>  
                </div>  
            </div>
			{
						this.state.exitsProperties === true
						? <div className="ltn__banner-area pt-20 go-top">
							<div className="container">
							<h4 className="title-2 mb-10">Map of properties</h4>
							<br></br>
							<Mapbox stores={this.state.stores} center={this.state.center}/>
							<br></br>
							</div>  
						</div>
						: ""
				} 
        {/* <Table_Condo streetNumber='6799' streetName='Collins Ave' condo="Carrillon South"/> */}
        <CallToActionV1 />
        <Footer />
    </div>
	}
}

export default Condo_List

