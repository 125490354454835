import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';



class CallToActionSFH extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__call-to-action-area ltn__call-to-action-4 bg-image pt-115 pb-120 mb-10 bg-overlay-theme-black-50" data-bs-bg={publicUrl+"assets/img/banner/sfh.png"}>
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="call-to-action-inner call-to-action-inner-4 text-center">
						<div className="section-title-area ltn__section-title-2">
						{/*<h6 className="section-subtitle ltn__secondary-color">//  any question you have  //</h6>*/}
						<h1 className="section-title white-color">Welcome to our curated list of the best luxury homes in the Miami area</h1>
						</div>
						<div className="btn-wrapper">
						<a href="https://calendly.com/blokhaus-service/30min?month=2022-12" className="theme-btn-1 btn btn-effect-1">SCHEDULE A CALL</a>
						 <span className="go-top"><Link to="/contact" className="btn btn-transparent btn-effect-4 white-color">CONTACT US</Link></span>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default CallToActionSFH