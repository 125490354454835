import axios from 'axios';

export const propertyService = () => {

    const BASE_URL = "https://api.bridgedataoutput.com/api/v2/OData/miamire/Properties";

    const mapData = (data) => {
        let mediaURL = []
        if(data.Media){
            data.Media.forEach(element => {
                mediaURL.push(element.MediaURL)
            });
        }
        
        let property = {
            id: data.ListingKey,
            listingId: data.ListingId,
            unitNumber: data.UnitNumber,
            address: data.UnparsedAddress,
            city: data.City,
            officeId: data.BuyerOfficeMlsId,
            officebH:data.ListOfficeMlsID,
            dateUpdated: data.ContractStatusChangeDate,
            streetName: data.StreetName,
            streetNumber: data.StreetNumber,
            countyOrParish: data.CountyOrParish,
            postalCode: data.PostalCode,
            buildName: data.BuildingName,
            listPrice: data.ListPrice,
            description: data.PublicRemarks,
            type: data.PropertyType, //residential residential lease
            subType: data.PropertySubType, //condo single family residential... 
            status: data.MlsStatus, //active closed pending
            beds: data.BedroomsTotal,
            baths: data.BathroomsFull,
            area: data.MLSAreaMajor,
            squareFeet: data.LivingArea,
            yearBuilt: data.YearBuilt,
            garages: data.GarageSpaces,
            closePrice: data.ClosePrice,
            daysOnMarket: data.DaysOnMarket,
            previousListPrice: data.PreviousListPrice,
            lotSize: data.LotSizeSquareFeet,
            closeDate: data.CloseDate,
            onMarketDay: data.OnMarketDate,
            taxLegalDescription: data.TaxLegalDescription,
            media: mediaURL,
            listingTerms: data.ListingTerms,
            //FEATURES
            interiorFeatures: data.InteriorFeatures,
            exteriorFeatures: data.ExteriorFeatures,
            laundryFeatures: data.LaundryFeatures,
            parkingFeatures: data.ParkingFeatures,
            poolFeatures: data.PoolFeatures,
            roomBedroomFeatures: data.RoomBedroomFeatures,
            securityFeatures: data.SecurityFeatures,
            spaFeatures: data.SpaFeatures,
            waterfrontFeatures: data.WaterfrontFeatures,
            windowFeatures: data.WindowFeatures,
            accessibilityFeatures: data.AccessibilityFeatures,
            appliances: data.Appliances,
            buildingFeatures: data.BuildingFeatures,
            communityFeatures: data.CommunityFeatures,
            doorFeatures: data.DoorFeatures,
            fireplaceFeatures: data.FireplaceFeatures,
            roomDiningRoomFeatures: data.RoomDiningRoomFeatures,
            roomLivingRoomFeatures: data.RoomLivingRoomFeatures,
            roomMasterBathroomFeatures: data.RoomMasterBathroomFeatures,
            associationYN: data.AssociationYN,
            attachedGarageYN: data.AttachedGarageYN,
            carportYN: data.CarportYN,
            associationAmenities: data.AssociationAmenities,
            coolingYN: data.CoolingYN,
            electricOnPropertyYN: data.ElectricOnPropertyYN,
            fireplaceYN: data.FireplaceYN,
            garageYN: data.GarageYN,
            habitableResidenceYN: data.HabitableResidenceYN,
            heatingYN: data.HeatingYN,
            HomeWarrantyYN: data.HomeWarrantyYN,
            horseYN: data.HorseYN,
            idxParticipationYN: data.IDXParticipationYN,
            internetAddressDisplayYN: data.InternetAddressDisplayYN,
            landLeaseYN: data.LandLeaseYN,
            leaseAssignableYN: data.LeaseAssignableYN,
            openParkingYN: data.OpenParkingYN,
            poolPrivateYN: data.PoolPrivateYN,
            propertyAttachedYN: data.PropertyAttachedYN,
            seniorCommunityYN: data.SeniorCommunityYN,
            signOnPropertyYN: data.SignOnPropertyYN,
            spaYN: data.SpaYN,
            viewYN: data.ViewYN,
            view: data.View,
            waterfrontYN: data.WaterfrontYN,
            coordinates: data.Coordinates
        }   
        return property;
    }

    //method that get a property by id
    
    const getPropertyByID = async (id) => {
        let endpoint = BASE_URL+"('"+id+"')"
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c'
        }
        const response = await axios.get(endpoint, {params})
        let res = await response.data;
        return mapData(res);
    };

    // const getPropertyByAddress = async (address) => {
    //     let filter = `UnparsedAddress eq '${address}' and MlsStatus eq 'Active'`
    //     let params = {
    //         access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
    //         $filter: filter
    //     }
    //     const response = await axios.get(BASE_URL, {params})
    //     let res = await response.data;
    //     return mapData(res);
    // };

    const getProperties = async (params) => {
        let properties = [];
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
            if(element.PropertyType === "Residential"){
                if (element.ListPrice >= 300000 && element.ListPrice <= 1000000000){
                    properties.push(mapData(element));
                }
            } else properties.push(mapData(element));
        })
        return properties;
    };

    const getPropertiesCondo = async (streetName, streetNumber, status) => {
        let properties = [];
        let order = "";
        if (status === "Active" || status === "Pending"){
            order = 'ListPrice asc'
        } else order = 'CloseDate asc'
        let filter = `PropertySubType eq 'Condominium' and contains(StreetName,'${streetName}') and contains(StreetNumber,'${streetNumber}') and MlsStatus eq '${status}'`
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
            $orderby: order
        }
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
            if(element.PropertyType === "Residential"){
                if (element.ListPrice >= 300000 && element.ListPrice <= 1000000000){
                    properties.push(mapData(element));
                }
            } else properties.push(mapData(element));
        })
        return properties;
    };

    const getPropertiesOffice = async (office) => {
        let properties = [];
        let filter = `ListOfficeMlsId eq '${office}'`
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
        }
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
            if(element.BuyerOfficeMlsId === "JMAZ01"){
                if (element.ListPrice >= 300000 && element.ListPrice <= 1000000000){
                    properties.push(mapData(element));
                }
            } else properties.push(mapData(element));
        })
        return properties;
    };

    const getPropertiesSFH = async (city, status) => {
        let properties = [];
        let order = "";
        if (status === "Active" || status === "Pending"){
            order = 'ListPrice asc'
        } else order = 'CloseDate desc'
        let filter = `PropertySubType eq 'Single Family Residence' and ((City eq '${city}') or (MLSAreaMajor eq '${city}')) and MlsStatus eq '${status}'` ;

        if(city === "Start, Hibiscus and Palm Isles"){
            filter = `PropertySubType eq 'Single Family Residence' and (((City eq 'Start') or (MLSAreaMajor eq 'Start')) or ((City eq 'Hibiscus') or (MLSAreaMajor eq 'Hibiscus')) or ((City eq 'Palm Isles') or (MLSAreaMajor eq 'Palm Isles'))) and MlsStatus eq '${status}'`
        }else if (city === "Wynwood and Midtown"){
            filter = `PropertySubType eq 'Single Family Residence' and (((City eq 'Wynwood') or (MLSAreaMajor eq 'Wynwood')) or ((City eq 'Midtown') or (MLSAreaMajor eq 'Midtown'))) and MlsStatus eq '${status}'`
        }

        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
            $orderby: order
        }
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
            if(element.PropertyType === "Residential"){
                if (element.ListPrice >= 300000 && element.ListPrice <= 1000000000){
                    properties.push(mapData(element));
                }
            } else properties.push(mapData(element));
        })
        return properties;
    };

    const getCitySFH = async () => {
        // let totalSFH = 0;
        let cities = [];
        let filter = `PropertySubType eq 'Condominium' and MlsStatus eq 'Active'` ;
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
        }
        // const response = await axios.get(BASE_URL, {params})
        // totalSFH = await response.data["@odata.count"];

        for (let i=0; i<51; i++){
            params = {
                access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
                $filter: filter,
                $skip: i*200,
                $select: 'City',
                $top: '200',
                $orderby: 'CloseDate desc'
            }
            const response = await axios.get(BASE_URL, {params})
            let res = await response.data
            res.value.forEach((item)=> {
                cities.push(item.City)
            })
        }
        return cities.filter((item, index)=>{
            return cities.indexOf(item) === index;
        });
    }

    const getRelatedProperties= async (type, streetName, streetNumber) =>{
        let properties = [];
        let filter = `PropertySubType eq '${type}' and contains(StreetName,'${streetName}') and contains(StreetNumber,'${streetNumber}')`
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '2',
        }
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
             properties.push(mapData(element));
        })
        return properties;
    }

    const getImageCondo = async (streetName, streetNumber) => {
        let properties = [];
        let filter = `PropertySubType eq 'Condominium' and contains(StreetName,'${streetName}') and contains(StreetNumber,'${streetNumber}')`
        let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
        }
        const response = await axios.get(BASE_URL, {params})
        let res = await response.data;
        res.value.forEach((element)=> {
             properties.push(mapData(element));
        })
        return properties;
    }

    return {
        getProperties,
        getPropertyByID,
        getPropertiesCondo,
        getPropertiesSFH,
        getPropertiesOffice,
        getCitySFH,
        getRelatedProperties,
        getImageCondo
    };

    

    
}