import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = props => {

	let images = [];
	if(props.media){
		images = props.media.map((item) => {
			return {
				original: item,
				thumbnail:item,
			  };
		})
	}

    return <ImageGallery items={images} thumbnailPosition="left" showPlayButton={false} showIndex={true} additionalClass="gallery" showFullscreenButton={false} />
	// <div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-20 plr--7">
	// 		<div className="container-fluid">
	// 			<div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
	// 				{
	// 					img.map((item) => (
	// 						<div className="col-lg-12">
	// 							<div className="product-img go-top">
	// 								<a href={item}>
	// 									<img className="photos" src={item} alt="#" />
	// 								</a>
	// 							</div>
	// 						</div>
	// 					))
	// 				}
					
	// 			</div>
	// 		</div>

	// 	</div>
	
	
	
}

export default Gallery