import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import CustomForm from '../global-components/CustomForm'

class CallToActonV1 extends Component {
	
    render() {
		const postUrl = `https://blokhausre.us9.list-manage.com/subscribe/post?u=b63fbcb8467c7653140af34eb&id=3f8e138daf`;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
			          <div className="coll-to-info text-color-white">
			            <h1>Looking for a dream home?</h1>
			            <p>We can help you realize your dream of a new home</p>
			          </div>
			          <div className="ltn__search-widget">     
                    <form action="https://blokhausre.us9.list-manage.com/subscribe/post?u=b63fbcb8467c7653140af34eb&id=3f8e138daf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_self" className="ltn__form-box">
                            <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Your E-mail..." />
                            <button type="submit" name="submit" value="Subscribe" className="btn-1 btn-white btn-effect-1" >SUBMIT</button>
                        </form>
	 
                    <div className="ltn__newsletter-bg-icon">
                        <i className="fas fa-envelope-open-text" />
                    </div>
                    </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CallToActonV1