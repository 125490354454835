import React, { Component } from "react";
import { Redirect} from "react-router-dom";
import { propertyService } from "../../utils/propertyService";
import DataTable from 'react-data-table-component';
import jsonData from './condos.json'
import Header from "../global-components/header";
import CallToActionV1 from "../section-components/call-to-action-v1";
import Footer from "../global-components/footer";
import PageHeader from '../global-components/page-header';
import { thisTypeAnnotation } from "@babel/types";

class Table_Condo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProperties: [],
      pendingProperties: [],
      closedProperties: [],
      activeData: [],
      pendingData: [],
      closedData: [],
      redirect: false,
      url: "",
      filter: "",
      name: "",
      streetName: "",
      streetNumber: "",
      activeRent: "",
      activeSale: "",
      loadTableActive: "",
      loadTablePending: "",
      loadTableClosed: "",
      exitProperties: false,
      city: {
        City: "",
        Endpoint: "",
        Image: "",
        CondosRelevant: [],
        Condos: []
      },
    };
    this.state = {isToggleOn: true};
    this.state = {
      loadTableActive: true,
      loadTableClosed: true,
      loadTablePending: true
    }

    // This binding is necessary to make `this` work in the callback
    // this.handleClick = this.handleClick.bind(this);
  } 

  componentDidMount() {
    localStorage.setItem('url',window.location.href)
    let api = propertyService();
    let city = jsonData.filter(item => {
      return item.Condos.filter(condo => condo.Endpoint == this.props.match.params.condo)[0]
    })[0];
    let condo = city.Condos.filter(condo => condo.Endpoint == this.props.match.params.condo)[0]
    this.setState({
      name: condo.Name,
      streetName:  condo.streetName,
      streetNumber: condo.streetNumber,
      activeRent: false,
      activeSale: true,
      city: city
    }
    )

    //get active data
    api.getPropertiesCondo(condo.StreetName,condo.StreetNumber, "Active").then((data) => {
      this.setState({
        activeProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) != 0
              ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          unitNumber: item.unitNumber,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          previousPrice: item.previousListPrice ? "$" +parseInt(item.previousListPrice).toLocaleString("en-US") : "N/A",
          previousSqft: isFinite(parseFloat(item.previousListPrice / item.squareFeet)) 
            ? parseFloat(item.previousListPrice / item.squareFeet) != 0
                ? "$" + parseFloat(item.previousListPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") 
                : "N/A"
            : "N/A",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          type: item.type
        })
        )
      });
  
          this.setState({
            exitProperties: true
          })

      this.setState(
        {
          activeData: this.state.activeProperties.filter(row => row.type == "Residential"),
        }
      )
      this.setState({
        loadTableActive : false
      })

    });
    //get pending data
    api.getPropertiesCondo(condo.StreetName,condo.StreetNumber, "Pending").then((data) => {
      this.setState({
        pendingProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) != 0
              ? parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.listPrice - item.previousListPrice) / item.previousListPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          unitNumber: item.unitNumber,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          previousPrice: item.previousListPrice ? "$" +parseInt(item.previousListPrice).toLocaleString("en-US") : "N/A",
          previousSqft: isFinite(parseFloat(item.previousListPrice / item.squareFeet)) 
            ? parseFloat(item.previousListPrice / item.squareFeet) != 0
                ? "$" + parseFloat(item.previousListPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") 
                : "N/A"
            : "N/A",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          type: item.type
        })
        )
      });
      this.setState(
        {
          pendingData: this.state.pendingProperties.filter(row => row.type == "Residential"),
        }
      )
      this.setState({
        loadTablePending : false
      })
    });
    //get closed data
    api.getPropertiesCondo(condo.StreetName,condo.StreetNumber, "Closed").then((data) => {
      this.setState({
        closedProperties: data.map((item) =>
        ({
          id: item.id,
          perChange: isFinite(parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toLocaleString("en-US")) 
          ? parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100) != 0
              ? parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100) > 0
                ? "🠉  "+parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
                : "🠋  "+parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%"
              : parseFloat(((item.closePrice - item.listPrice) / item.listPrice) * 100).toFixed(2).toLocaleString("en-US") + "%" 
          : "N/A",
          unitNumber: item.unitNumber,
          listPrice: "$" + parseInt(item.listPrice).toLocaleString("en-US"),
          sqft: item.squareFeet ? parseInt(item.squareFeet).toLocaleString("en-US") : "N/A",
          listSqft: isFinite(parseFloat(item.listPrice / item.squareFeet)) ? "$" + parseFloat(item.listPrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          salePrice: "$" + parseInt(item.closePrice).toLocaleString("en-US"),
          saleSqft: isFinite(parseFloat(item.closePrice / item.squareFeet)) ? "$" + parseFloat(item.closePrice / item.squareFeet).toFixed(2).toLocaleString("en-US") : "N/A",
          beds: item.beds,
          baths: item.baths,
          daysOnMarket: item.daysOnMarket,
          closeDate: item.closeDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$2/$3/$1'),
          type: item.type
        }))
      });

      this.setState(
        {
          closedData: this.state.closedProperties.filter(row => row.type == "Residential"),
        }
      )
      this.setState({
        loadTableClosed : false
      })
    });
  }

  goToPropertyDetail = row => {
    let url = `/product-details/${row.id}`;
    this.setState({
      redirect: true,
      url: url
    })
  }

  clickHandler = (state) => {
    let url = `/product-details/${state.target.id}`;
    this.setState({
      redirect: true,
      url: url
    })
  };

  // handleClick() {
  //   this.setState(prevState => ({
  //     isToggleOn: !prevState.isToggleOn
  //   }));
  //     if (this.state.isToggleOn == true){
  //       this.setState(
  //         {
  //           activeData: this.state.activeProperties.filter(row => row.type == "Residential"),
  //           pendingData: this.state.pendingProperties.filter(row => row.type == "Residential"),
  //           closedData: this.state.closedProperties.filter(row => row.type == "Residential")
  //         }
  //       )
  //     }
  //     if (this.state.isToggleOn == false){
  //       this.setState(
  //         {
  //           activeData: this.state.activeProperties.filter(row => row.type == "Residential Lease"),
  //           pendingData: this.state.pendingProperties.filter(row => row.type == "Residential Lease"),
  //           closedData: this.state.closedProperties.filter(row => row.type == "Residential Lease")
  //         }
  //       )
  //     }
  // }

  getRentProperties = () => {
    this.setState({activeRent: true,activeSale: false});
    this.setState(
      {
        activeData: this.state.activeProperties?.filter(row => row.type == "Residential Lease"),
        pendingData: this.state.pendingProperties?.filter(row => row.type == "Residential Lease"),
        closedData: this.state.closedProperties?.filter(row => row.type == "Residential Lease")
      }
    )
  }

  getSaleProperties = () => {
    this.setState({activeRent: false,activeSale: true});
    this.setState(
      {
        activeData: this.state.activeProperties?.filter(row => row.type == "Residential"),
        pendingData: this.state.pendingProperties?.filter(row => row.type == "Residential"),
        closedData: this.state.closedProperties?.filter(row => row.type == "Residential")
      }
    )
  }

  render() {

    let classRent;
    let classSale;
    if(this.state.activeRent==true){
      classRent = "tab-rent-sale-active show tab-rent-sale";
      classSale = "show tab-rent-sale";
    }else if (this.state.activeSale == true){
      classRent = "show tab-rent-sale";
      classSale = "show tab-rent-sale tab-rent-sale-active";
    }

    const columnsActivePending = [
      {
        name: '$ % Change',
        whiteSpace: 'normal',
        width: '105px',
        selector: row => row.perChange,
        conditionalCellStyles: [
          {
            when: row => String(row.perChange).includes("🠉") == true,
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => String(row.perChange).includes("🠋") == true,
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Unit #',
        selector: row => row.unitNumber,
      },
      {
        name: 'Listing Price',
        width: '120px',
        selector: row => row.listPrice,
      },
      {
        name: 'Sqft',
        width: '80px',
        selector: row => row.sqft,
      },
      {
        name: 'List $/Sqft',
        width: '120px',
        selector: row => row.listSqft,
      },
      {
        name: 'Previous Price',
        width: '120px',
        selector: row => row.previousPrice,
      }
      ,
      {
        name: 'Previous $/Sqft',
        width: '140px',
        selector: row => row.previousSqft,
      },
      {
        name: 'Beds',
        width: '60px',
        selector: row => row.beds,
      },
      {
        name: 'Baths',
        width: '70px',
        selector: row => row.baths,
      }, {
        name: 'Days On Market',
        width: '110px',
        height: 'auto',
        selector: row => row.daysOnMarket,
      }, {
        name: 'Type',
        selector: row => row.type,
        omit: true
      },
      {
        cell:(row) => <button className="detail-button-table" onClick={this.clickHandler} id={row.id} >Details</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    const columnsClosed = [
      {
        name: '$ % Change',
        whiteSpace: 'normal',
        width: '105px',
        selector: row => row.perChange,
        conditionalCellStyles: [
          {
            when: row => String(row.perChange).includes("🠉") == true,
            style: {
              color: '#13ce66', fontWeight: 'bold'
            }
          },
          {
            when: row => String(row.perChange).includes("🠋") == true,
            style: {
              color: '#ff4949', fontWeight: 'bold'
            }
          }
        ]
      },
      {
        name: 'Unit #',
        width: '90px',
        selector: row => row.unitNumber,
      },
      {
        name: 'Listing Price',
        width: '110px',
        selector: row => row.listPrice,
      },
      {
        name: 'Sqft',
        width: '70px',
        selector: row => row.sqft,
      },
      {
        name: 'List $/Sqft',
        width: '95px',
        selector: row => row.listSqft,
      }, {
        name: 'Sales Price',
        width: '110px',
        selector: row => row.salePrice,
      },
      {
        name: 'Sales $/Sqft',
        width: '110px',
        selector: row => row.saleSqft,
      },
      {
        name: 'Beds',
        width: '60px',
        selector: row => row.beds,
      },
      {
        name: 'Baths',
        width: '65px',
        selector: row => row.baths,
      }, {
        name: 'Days On Market',
        width: '92px',
        height: 'auto',
        selector: row => row.daysOnMarket,
      }, {
        name: 'Closing Date',
        width: '110px',
        height: 'auto',
        selector: row => row.closeDate,
      },
      {
        name: 'Type',
        selector: row => row.type,
        omit: true
      },
      {
        cell:(row) => <button className="detail-button-table" onClick={this.clickHandler} id={row.id} >Details</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '72px',
          fontSize: '14px',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#e5eaee",
            overflow: 'hidden',
            whiteSpace: 'normal',
          }
          // override the row height
        },
      },
      headCells: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          paddingLeft: '4px',
          justifyContent: 'center',
          backgroundColor: '#f2f6f7',
          color: '#5c727d',
          overflow: 'hidden',
          whiteSpace: 'normal !important',
        },
      },
      cells: {
        style: {
          justifyContent: 'center',
          paddingLeft: '4px',
          overflow: 'hidden',
          whiteSpace: 'normal',

          // width: '7%'
        },
      },
      pagination: {
        style: {
          backgroundColor: '#f2f6f7',
          color: '#5c727d'
        }
      }
    };

    if (this.state.redirect) {
      return <Redirect to={this.state.url} />
    }

    let breadcrumbs = [];
    breadcrumbs.push({
      name: 'Condos',
      url: 'condos'
    })
    breadcrumbs.push({
      name:this.state.city?.City,
      url: `condo/${this.state.city?.Endpoint}`
    })

    return (
      <div>
      <Header/>
      <PageHeader headertitle= {this.state.name} customclass="mb-0" breadcrumbs={breadcrumbs}/>
      <div className={"ltn__apartments-plan-area pt-115--- pb-70 mt-20"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Condominium 
                </h6>
                <h1 className="section-title">{this.state.name}</h1>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_1">
                    Available
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_2">
                    Pending
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    Closed
                  </a>
                </div>
              </div>
              <div className="tab-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white--- p-4">
                      <div className="ltn__shop-options">
                        <ul>
                        <div className="ltn__car-dealer-form-tab">
                            <div className="ltn__tab-menu  text-uppercase">
                            <div className="nav">
                              <a className={classSale} data-bs-toggle="tab" href="#ltn__form_tab_1_1" onClick={this.getSaleProperties}><i className="fas fa-home" />For Sale</a>
                              <a data-bs-toggle="tab" onClick={this.getRentProperties} className={classRent}><i className="fas fa-home" />For Rent</a>
                            </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                      <div className="tab-pane fade active show" id="liton_tab_3_1">
                        <div className="ltn__apartments-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                                <DataTable columns={columnsActivePending}
                                  data={this.state.activeData}
                                  pagination
                                  onRowClicked={this.goToPropertyDetail}
                                  customStyles={customStyles}
                                  noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                                  progressPending={this.state.loadTableActive}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                                <DataTable columns={columnsActivePending}
                                  data={this.state.pendingData}
                                  pagination
                                  onRowClicked={this.goToPropertyDetail}
                                  customStyles={customStyles}
                                  noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                                  progressPending={this.state.loadTablePending}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_3">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <DataTable columns={columnsClosed}
                                data={this.state.closedData}
                                pagination
                                onRowClicked={this.goToPropertyDetail}
                                customStyles={customStyles}
                                noDataComponent={<div style={{padding: "24px"}}>No properties found</div>}
                                progressPending={this.state.loadTableClosed}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToActionV1 />
      <Footer />
      </div>
    );
  }
}

export default Table_Condo;
