import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Team from '../team/teamcomponent';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';

const BhTeam = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Our Agent" subheader="Agent" />
        <Team />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BhTeam

