import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import {propertyService} from '../../utils/propertyService';

class FeaturedProperties extends Component {
    //JMAZ01 
    constructor (props){
        super (props);
        this.state = {propertyState:[]};
    }
    componentDidMount(){
        let property=[];
        let api = propertyService();
        api.getPropertiesOffice("JMAZ01").then( data => {
			
            data.forEach(element => {
				if (element.status == "Active"){
				property.push(element)
				console.log(element);
				}
			})
            this.setState({propertyState:property })
        });
    }

    render() {
        let propertVal = this.state.propertyState;
        let publicUrl = process.env.PUBLIC_URL+'/';
    return <div>
	
			<div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-50 plr--7">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>
			          <h1 className="section-title">Featured Listings</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to={`/product-details/${propertVal[9]?.media[0]}`}><img className="photos" src={propertVal[9]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[9]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to={`/product-details/${propertVal[9]?.id}`}><i className="flaticon-pin" /> {propertVal[9]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to={`/product-details/${propertVal[9]?.id}`}><i className="fas fa-camera" /> {propertVal[9]?.media.lengh}</Link>
			                  </li>
			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[9]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[9]?.id}`}>{propertVal[9]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[9]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[9]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[9]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[9]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[6]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[6]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[6]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[6]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[6]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[6]?.id}`}>{propertVal[6]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[6]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[6]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[6]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[6]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[3]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[3]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[3]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[3]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[3]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[3]?.id}`}>{propertVal[3]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[3]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[3]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[3]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[3]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[7]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[7]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[7]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[7]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[7]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[7]?.id}`}>{propertVal[7]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[7]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[7]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[7]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[7]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[5]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[5]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[5]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[5]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[5]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[5]?.id}`}>{propertVal[5]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[5]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[5]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[5]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[5]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
				{/* ltn__product-item */}
				<div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[2]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[2]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[2]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[2]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[2]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[2]?.id}`}>{propertVal[2]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[2]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[2]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[2]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[2]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
				{/* ltn__product-item */}
				<div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[8]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[8]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[8]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[8]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[8]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[7]?.id}`}>{propertVal[8]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[8]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[8]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[8]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[8]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
				   {/* ltn__product-item */}
				<div className="col-lg-12">
				 	<div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img className="photos" src={propertVal[9]?.media[0]} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge bg-green">{propertVal[9]?.type}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> {propertVal[9]?.city}</Link>
			                  </li>
			                </ul>
			              </div>
			              <div className="product-img-gallery go-top">
			                <ul>
			                  <li>
			                    <Link to="/product-details"><i className="fas fa-camera" /> {propertVal[9]?.media.lengh}</Link>
			                  </li>

			                </ul>
			              </div>
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>$ {parseInt(propertVal[9]?.listPrice).toLocaleString("en-US")}</span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/product-details/${propertVal[9]?.id}`}>{propertVal[9]?.buildName}</Link></h2>
			            <div className="product-description">
			              <p>{propertVal[9]?.address}</p>
			            </div>
			            <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{propertVal[9]?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{propertVal[9]?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{propertVal[9]?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		   </div>
        }
}

export default FeaturedProperties