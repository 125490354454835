import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

class ProductSliderV1 extends Component {

	render() {

        let publicUrl = process.env.PUBLIC_URL+'/';
		let Images = this.props.images;
		const responsive = {
            0: { items: 1 },
            568: { items: 2 },
            1024: { items: 3 },
        };
        
        const items = [
            <a href={Images[0]} data-rel="lightcase:myCollection" className="slider-container-image">
				<img src={Images[0]} alt="Image" />
			</a>,
            <a href={Images[1]} data-rel="lightcase:myCollection" className="slider-container-image">
                <img src={Images[1]} alt="Image" />
            </a>,
            <a href={Images[2]} data-rel="lightcase:myCollection" className="slider-container-image">
                <img src={Images[2]} alt="Image" />
            </a>
        ];
       

    return <div className="ltn__img-slider-area mb-90" id='product-detail--slider'>
						<AliceCarousel
						mouseTracking
						items={items}
						responsive={responsive}
						infinite={true}
						disableButtonsControls= {true}
						//disableDotsControls={true}
						controlsStrategy="alternate"
						/>
			</div>
        }

    
}

export default ProductSliderV1