import React, { Component } from "react";
import Header from './global-components/header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {propertyService} from '../utils/propertyService';
import PageHeader from './global-components/page-header';
import jsonData from '../components/condos/condos.json';

class Product_Details extends Component {

    constructor(props){
        super(props);
        this.state = {
            property: [],
            relatedProperties: [],
            images:[],
            breadcrumbs: []
        }
    }
    
    componentDidMount(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "src/utils/propertyService.js";

        document.body.appendChild(minscript);
        
        let api = propertyService();
        let id = this.props.match.params.propertyId;
        api.getPropertyByID(id)
            .then( data  => {
                this.setState({
                    property: data
                });
                this.setState({
                    images: this.state.property.media.slice(0,5)
                })
        });
        //window.location.reload()
        let prevUrl =  localStorage.getItem('url');
        let breadcrumbs = []
        if(prevUrl !== null){
            let paths = prevUrl.split('/');
            if(paths[paths.length-2] == 'condo-detail'){
                let url1 = paths[paths.length-1].replace(/-/g, ' ').split(" ");
                let city = jsonData.filter(item => {
                    return item.Condos.filter(condo => condo.Endpoint == paths[paths.length-1] )[0]
                })[0]
                for (let i = 0; i < url1.length; i++) {
                    url1[i] = url1[i][0].toUpperCase() + url1[i].substr(1);
                }
                breadcrumbs.push({
                    name: 'Condos',
                    url: 'condos'
                });
                breadcrumbs.push({
                    name: city.City,
                    url: `condo/${city.Endpoint}`
                });
                breadcrumbs.push({
                    name: url1.join(" "),
                    url: `condo-detail/${paths[paths.length-1]}`
                });
                this.setState({
                    breadcrumbs: breadcrumbs
                })
            } else if (paths[paths.length-2] == 'single-family-home'){
                let url1 = paths[paths.length-1].replace(/-/g, ' ').split(" ");
                for (let i = 0; i < url1.length; i++) {
                    url1[i] = url1[i][0].toUpperCase() + url1[i].substr(1);
                }
                breadcrumbs.push({
                    name: "Single Family Homes",
                    url: "single-family-homes"
                });
                breadcrumbs.push({
                    name: url1.join(" "),
                    url: `single-family-home/${paths[paths.length-1]}`
                });
                this.setState({
                    breadcrumbs: breadcrumbs
                })

            }
        }
        
        localStorage.setItem('url', '')
    }

    render(){

        let images = [];
        if(this.state.images){
            images = this.state.images;
        };
      
       
        return <div> 
            <Header />
            <PageHeader headertitle= {this.state.property.address} customclass="mb-0" breadcrumbs={this.state.breadcrumbs}/>
            <ProductSlider images={images}/>
            <ProductDetails property={this.state.property} relatedProperties={this.state.relatedProperties.slice(0,10)} />
            <CallToActionV1 />
            <Footer />
        </div>
    }
}

export default Product_Details

