import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import TeamHome from './team-home';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-50 pb-50 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
				  <div className="about-us-img-wrap about-img-left">
					<TeamHome/>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">About Us</h6>
			            <h1 className="section-title">The Leading <span>Real Estate </span>
			              Team You Can Trust</h1>
			            <p>With over 15 years’ experience selling South Florida properties and over $600 million closed in commercial and residential real estate transactions, Juan also takes pride in his education: Bachelor of Science in Industrial Engineering and Master’s degrees in International Real Estate (MSIRE) and Finance (MSF) as well as his recent certification from famed Harvard University on FINTECH (financial technology).</p>
			          </div>                        
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-house-4" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4>Market knowledge</h4>
			              <p>We analyse the market and create our own statistics creating valuable data to investors and market experts</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-call-center-agent" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4>World Wide Coverage</h4>
			              <p>Our Multidisciplinary team is perfectly suited to address international investors with the best suggestions and approaches</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-maps-and-location" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4>Real Estate Intelligence</h4>
			              <p>We conduct Real Estate Business Intelligence to help investors through the best investment decision</p>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV3