import React from 'react';
import Header from './global-components/header';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const About_Juan = () => {
    return <div>
        <Header />
        <PageHeader headertitle="About Juan Alvarez" />
        <AboutV4 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_Juan

