import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = props => {

	let images = [];
	if(props.media){
		images = props.media.map((item) => {
			return {
				original: item,
				thumbnail:item,
			  };
		})
	}

    return <ImageGallery items={images} thumbnailPosition="left" showPlayButton={false} showIndex={true} additionalClass="gallery" showFullscreenButton={false} />
	
}

export default Gallery

{/*
const Gallery = props => {

    return <div className="ltn__img-slider-area">
				<div className="container-fluid">
				<div className="row ltn__image-slider-4-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
					{
						props.media?.map((image) => (
							<div className="col-lg-12 mx-1">
								<div className="ltn__img-slide-item-4 gallery-detail">
									<a key={image}  href={process.env.PUBLIC_URL+image} data-rel="lightcase:myCollection">
									<img key={image} src={process.env.PUBLIC_URL+image} alt="Image" />
									</a>
								</div>
							</div>
						))
					}
				</div>
				</div>
			</div>
}

export default Gallery
*/}
