import React, { Component,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import {propertyService} from '../../utils/propertyService';

class PropertiesGrid extends Component {
    constructor (props){
        super (props);
        this.state = {propertyState:[]};
    }
    componentDidMount(){
        let property=[];
        let api = propertyService();
        api.getPropertiesOffice("JMAZ01").then( data => {
			
            data.forEach(element => {
				if (element.status == "Active"){
				property.push(element)
				}
			})
            this.setState({propertyState:property })
        });
    }
    render() {
        let propertVal = this.state.propertyState;
        let publicUrl = process.env.PUBLIC_URL +'/';
    return <div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12 pt-50">
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
									<div className="row">
									{/* ltn__product-item */}
									
                                    {propertVal?.map((user) => (
                                        <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                        <div className="product-img">
                                        <Link to={`/product-details/${user?.id}`}><img className="photos" src={user?.media[0]} alt="#" /></Link>
                                        </div>
                                        <div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">{user?.type}</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to={`/product-details/${user?.id}`}>{user?.buildName}</Link></h2>
											<div className="product-description">
			                                      <p>{user?.address}</p>
			                                    </div>

                                                <ul className="ltn__list-item-2 ltn__list-item-2-before">
			              <li><span>{user?.beds+" "}<i className="flaticon-bed" /></span>
			                Bedrooms
			              </li>
			              <li><span>{user?.baths+" "}<i className="flaticon-clean" /></span>
			                Bathrooms
			              </li>
			              <li><span>{user?.squareFeet+" "} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			                square Ft
			              </li>
			            </ul>
                                            <div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to={`/product-details/${user?.id}`}><i className="flaticon-pin" /> {user?.city}</Link>
												</li>
											</ul>
											</div>
										</div>
                                        <div className="product-info-bottom">
											<div className="product-price">
											<h3>${parseInt(user?.listPrice).toLocaleString("en-US")}</h3>
											</div>
										</div>
                                        </div>
                                        </div>
													))}
									{/*  */}
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
				</div>
			</div>



			</div>

        }
}

export default PropertiesGrid