import React, { Component,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';

class PreConstructionGrid extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL;
    return <div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12 pt-50">
							{/*<div className="ltn__shop-options">
								<ul>
								<li>
									<div className="ltn__grid-list-tab-menu ">
									<div className="nav">
										<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
										<a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a>
									</div>
									</div>
								</li>
								<li>
									<div className="short-by text-center">
									<select className="nice-select">
										<option>Default sorting</option>
										<option>Sort by popularity</option>
										<option>Sort by new arrivals</option>
										<option>Sort by price: low to high</option>
										<option>Sort by price: high to low</option>
									</select>
									</div> 
									</li>
								<li>
									<div className="showing-product-number text-right">
									<span>Showing 9 of 20 results</span>
									</div> 
								</li>
								</ul>
							</div>*/}
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
									<div className="row">
									<div className="col-lg-12">
										{/* Search Widget
										<div className="ltn__search-widget mb-30">
										<form action="#">
											<input type="text" name="search" placeholder="Search your keyword..." />
											<button type="submit"><i className="fas fa-search" /></button>
										</form>
										</div>
										 */}
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">

										<div className="product-img">
											<Link to="/pre-construction/mwc"><img src={publicUrl+"/data/Banners/MWC_Banner.png"} alt="#" /></Link>

										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/mwc">Miami World Center</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/mwc"><i className="flaticon-pin" /> Downtown - Miami</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/mwc" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											 	<h3>From: <span>$400,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/elser"><img src={publicUrl+"/data/Banners/Society_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/elser">The Elser</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/elser"><i className="flaticon-pin" /> Downtown - Miami</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/elser" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$700,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/aria"><img src={publicUrl+"/data/Banners/Aria_Reserve_Banner.png"} alt="#" /></Link>
											{/*<div className="real-estate-agent">
											<div className="agent-img">
												<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
											</div>
										</div>*/}
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/aria">Aria Reserve</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/aria"><i className="flaticon-pin" /> Edgewater</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/aria" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											 <h3><span>From:</span> $1,100,000</h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/casabella"><img src={publicUrl+"/data/Banners/CasaBella_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/casabella">Casa Bella</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/casabella"><i className="flaticon-pin" /> Downtown - Miami</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/casa-bella" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$700,000 </span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/1428Brickell"><img src={publicUrl+"/data/Banners/1428Brickell_banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/residencesbrickell">1428 Brickell</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/residencesbrickell"><i className="flaticon-pin" /> Brickell</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/residencesbrickell" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$2,400,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/regisbrickell"><img src={publicUrl+"/data/Banners/StRegisBrickell_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/regisbrickell">St Regis Brickell</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/regisbrickell"><i className="flaticon-pin" /> Brickell</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/St-Brickell" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$1,900,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/regissunny"><img src={publicUrl+"/data/Banners/StRegisSunnyIsles_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/regissunny">St Regis Sunny Isles</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/regissunny"><i className="flaticon-pin" /> Sunny Isles Beach</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/product-details" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$4,500,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/la-baia"><img src={publicUrl+"/data/Banners/La Baia_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/la-baia">La Baia</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/la-baia"><i className="flaticon-pin" /> Bay Harbour Islands</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/la-baia" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$810,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/edition"><img src={publicUrl+"/data/Banners/THE EDITION.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/edition">The Edition</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/edition"><i className="flaticon-pin" /> Edgewater</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/edition" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$1,900,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/nomad"><img src={publicUrl+"/data/Banners/Nomad Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/nomad">NoMad</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/nomad"><i className="flaticon-pin" /> Wynwood</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/nomad" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$597,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/bentley"><img src={publicUrl+"/data/Banners/Bentley_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/bentley">Bentley</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/bentley"><i className="flaticon-pin" /> Sunny Isles Beach</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/pre-construction/bentley" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$3,800,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/* ltn__product-item */}
									<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/regissunny"><img src={publicUrl+"/data/Banners/Oasis_Banner.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/oasis">Oasis</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/oasis"><i className="flaticon-pin" /> Hallandale Beach</Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/product-details" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$490,000</span></h3>
											</div>
										</div>
										</div>
									</div>
																		{/* ltn__product-item */}
																		<div className="col-lg-4 col-sm-6 col-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to="/pre-construction/piersixtysix"><img src={publicUrl+"/data/Banners/Pier_Banner_EXT.png"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">For Sale</li>
											</ul>
											</div>
											<h2 className="product-title go-top"><Link to="/pre-construction/piersixtysix">Pier-Sixty Six </Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/pre-construction/piersixtysix"><i className="flaticon-pin" /> Fort Lauderdale Beach </Link>
												</li>
											</ul>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/product-details" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<h3>From: <span>$3,600,000</span></h3>
											</div>
										</div>
										</div>
									</div>
									{/*  */}
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
				</div>
			</div>



			</div>

        }
}

export default PreConstructionGrid