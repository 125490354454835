import React, { Component } from "react";
import Header from '../global-components/header';
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer from '../global-components/footer';
import { Link} from "react-router-dom";
import jsonData from "../single-family-home/city.json"
import Mapbox from '../section-components/mapbox';
import CallToAction from '../single-family-home/calltoaction';
import { propertyService } from "../../utils/propertyService";

class Landing_SFH extends Component {

	constructor(props){
		super(props);
		this.state = {
			stores: [],
			center: [-80.13243054906823,25.798891350759376],
			exitsProperties: false
		};
	}

	componentDidMount(){
		let api = propertyService();
		let filter = `PropertySubType eq 'Single Family Residence' and MlsStatus eq 'Active'` ;
		let order = 'ListPrice desc';
		let params = {
            access_token: '103a9efb0c687b2b6af63cc6f3f4177c',
            $filter: filter,
            $top: '200',
            $orderby: order
        }
		let features = [];
		api.getProperties(params)
		.then((data) => {
				console.log(data)
				if(data !== undefined){
					features = data.filter(item => item.coordinates !== null).map((item)=> ({
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: item.coordinates
						},
						properties: {
							price: item.listPrice,
							address: item.address,
							city: item.city,
							postalCode: item.postalCode,
							beds: item.beds,
							baths: item.baths,
							squareFeet: item.squareFeet,
							image: item.media[0],
							idList: item.id,
						}
					}))
					console.log(features)
					this.setState({
						stores : {
							type: "FeatureCollection",
							features: features
						}
					})
					this.setState({
						exitsProperties: true
					})
				}
				console.log(this.state.stores)
			}
			
		)
	}

	render(){

    let publicUrl = process.env.PUBLIC_URL+'/'
	  
		return <div>
			<Header />
			<CallToAction />
			<div className="ltn__banner-area pt-120 go-top">
					<div className="container">
					<div className="row">
						<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>
							<h1 className="section-title">Property By Neighborhoods</h1>
						</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Balharbour.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/bal-harbour">  Bal Harbour</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/CoconutGrove.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/coconut-grove">  Coconut Grove</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Surfside.jpg"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/surfside">  Surfside</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Hollywood.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/hollywood">  Hollywood</Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Miamibeach.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/miami-beach"> Miami Beach </Link></h3>
							</div>
						</div>
						</div>
						<div className="col-lg-4 col-md-6">
						<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Edgewater.png"} >                        
							<div className="ltn__banner-info">
							<h3><Link to="/single-family-home/edgewater">  Edgewater</Link></h3>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
				{
					this.state.exitsProperties === true
					? <div className="ltn__banner-area pt-20 go-top">
						<div className="container">
						<h4 className="title-2 mb-10">Map of properties</h4>
						<br></br>
						<Mapbox stores={this.state.stores} center={this.state.center}/>
						<br></br>
						</div>  
					</div>
					: ""
				}
				
				<div className="ltn__banner-area pt-20 go-top">
					<div className="container">
					<h4 className="title-2 mb-10">Directory of Neighborhoods</h4>
							<div className="property-details-amenities mb-60">
							<div className="row">
								<div className="col-lg-12 col-md-6">
								<div className="ltn__menu-widget">
									<ul className="amenities">
										{
											jsonData.map((city)=>(
												<li className='go-neighborhoods' key={city.Endpoint}>
													<Link to={"/single-family-home/"+city.Endpoint}>{city.Name}</Link>
												</li>
											))
										}
									</ul>
								</div>
								</div>
							</div>
							</div>  
					</div>  
				</div>
			{/* <Table_SFH city='Aventura'/> */}
			<CallToActionV1 />
			<Footer />
		</div>
	}
}

export default Landing_SFH