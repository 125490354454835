import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
        
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57477.74100593484!2d-80.139083!3d25.791734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b486f6558c43%3A0xe0b09ac90e107891!2s1680%20Michigan%20Ave%2C%20Miami%20Beach%2C%20FL%2033139!5e0!3m2!1ses!2sus!4v1671217014259!5m2!1ses!2sus" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map