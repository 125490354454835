import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../section-components/bannerprec';
import Header from '../global-components/navbar-v3';
import GalleryPc from '../shop-components/pre-construccion-gallery';
import ContactForm from '../section-components/contact-form';

class PcDetails extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/';
		let Name = this.props.name;
        let Description = this.props.Description;
		let floorplans = this.props.Floorplans;
		let images = this.props.Imgs;
		let VideoB = this.props.video;
		let Map = this.props.Location;
		let BannerU = this.props.Banner;
		let size = this.props.Sqft;
		let Location = this.props.City;
		let price = this.props.PriceInit;
		let videopath = this.props.videopath;
    return <div className="ltn__shop-details-area pb-10">
				<div className="pre-construction-container">
				<div className="row">
					<div className="mb-60">
						<Header/>
						<Banner Name={Name} Description={Description} video={VideoB} BannerC={BannerU} size={size} Loc={Location} price={price} localvid={videopath}/>
					</div>
				</div>
				</div>
				<div className="container">
				<div className="row">
					<div className="col-lg-8">
					<div className="widget Gallery"  id="Gallery">
							<h4 className="title-2">Gallery</h4>
							<GalleryPc media={images}/>
						</div>
						<div className="widget" >
						<h4 className="title-2">Floor Plans</h4>
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							{floorplans?.map((user) => (
										<a data-bs-toggle="tab" href={"#Tab"+user.Id}>{user.Name}</a>
													))}

							</div>
						</div>
						<div className="tab-content">
						{floorplans?.map((user) => (
								<div className="tab-pane fade" id={"Tab"+user.Id}>
								<div className="ltn__apartments-tab-content-inner">
									<div className="row">
									<div className="col-lg-7">
										<div className="apartments-plan-img">
										<img src={publicUrl+user.Img} alt="#" />
										</div>
									</div>
									<div className="col-lg-5">
										<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
										<h2>{user.Name}</h2>
										<p>{user.Description}</p>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="product-details-apartments-info-list  section-bg-1">
										<div className="row">
											<div className="col-lg-6">
											<div className="apartments-info-list apartments-info-list-color mt-40---">
												<ul>
												<li><label>Total Area</label> <span>{user.Size} Sq. Ft</span></li>
												<li><label>Bedroom</label> <span>{user.Bedrooms}</span></li>
												</ul>
											</div>
											</div>
											<div className="col-lg-6">
											<div className="apartments-info-list apartments-info-list-color mt-40---">
												<ul>
												<li><label>Bathrooms</label> <span>{user.Bathrooms}</span></li>
												<li><label>Lounge</label> <span>650 Sq. Ft</span></li>
												</ul>
											</div>
											</div>
										</div>
										</div>
									</div>
									</div>
								</div>
								</div>
													))}
						</div>
						</div>
						</div>
						<div className="widget" >
							<h4 className="title-2">Location</h4>
						<div className="property-details-google-map mb-60">
							<iframe src= {Map} width="80%" height="100%" allowFullScreen aria-hidden="false" tabIndex={0} />
						</div>
						</div>
						<div className="mb-100">
							<ContactForm/>
						</div>
					</div>	
					<div className="col-lg-4">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Author Widget */}
						<div className="widget ltn__author-widget">
						<div className="ltn__author-widget-inner text-center">
							<img src={publicUrl+"assets/img/sidebar/Juan-alvarez-1.jpg"} alt="Image" />
							<h5>Juan Alvarez</h5>
							<small>MSIRE – MSF - Broker Principal</small>
							<p>Real Estate Specialist </p>
							<div className="ltn__social-media">
							<ul>
								<li><a href="https://api.whatsapp.com/send/?phone=13057106804&text&type=phone_number&app_absent=0" title="Whatsapp"><i className="fab fa-whatsapp" /></a></li>
								<li><a href="https://www.linkedin.com/in/juan-alvarez-msire-msf-58310746/?original_referer=https%3A%2F%2Fblokhausre.com%2F" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
								<li><a href="https://www.instagram.com/juanmanueleto/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
							</div>
						</div>
						</div>
						{/* Form Widget */}
						<div className="widget ltn__form-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Request More Information</h4>
						<form action="#">
							<input type="text" name="yourname" placeholder="Your Name*" />
							<input type="text" name="youremail" placeholder="Your e-Mail*" />
							<input type="text" name="phone" placeholder="Enter phone number" />
							<textarea name="yourmessage" placeholder={"I am interested in "+ Name} defaultValue={""} />
							<button type="submit" className="btn theme-btn-1">Send Message</button>
						</form>
						</div>

						{/* Menu Widget (Category) */}
						<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Categories</h4>
						<ul className="go-top">
							<li><Link to="/condos">Condominium <span></span></Link></li>
							<li><Link to="/featured">Featured Properties <span></span></Link></li>
							<li><Link to="/pre-construction">Pre Construction  <span></span></Link></li>
							<li><Link to="/single-family-homes">Single Family Homes <span></span></Link></li>
						</ul>
						</div>
						{/* Popular Product Widget */}
						{/* <div className="widget ltn__popular-product-widget">       
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Featured Properties</h4>                     
						<div className="row ltn__popular-product-widget-active slick-arrow-1">
							{
								relatedProperties.map((item)=> (
									<div className="col-12">
									<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img go-top">
										<Link to="/shop"><img src={publicUrl+"assets/img/product-3/6.jpg"} alt="#" /></Link>
										<div className="real-estate-agent">
											<div className="agent-img">
											<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
											</div>
										</div>
										</div>
										<div className="product-info">
										<div className="product-price">
											<span>{item.listPrice}</span>
										</div>
										<h2 className="product-title"><Link to={"product-detail/"+item.id}>{item.address}</Link></h2>
										<div className="product-img-location">
											<ul>
											<li>
												<Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
											</li>
											</ul>
										</div>
										<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>3 </span>
											Bedrooms
											</li>
											<li><span>2 </span>
											Bathrooms
											</li>
											<li><span>3450 </span>
											square Ft
											</li>
										</ul>
										</div>
									</div>
									</div>
								))		
							} */}
{/* 						
						</div>
						</div> */}
						{/* Popular Post Widget 
						<div className="widget ltn__popular-post-widget go-top">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Leatest Blogs</h4>
						<ul>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/5.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/6.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/7.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
							<li>
							<div className="popular-post-widget-item clearfix">
								<div className="popular-post-widget-img">
								<Link to="/blog-details"><img src={publicUrl+"assets/img/team/8.jpg"} alt="#" /></Link>
								</div>
								<div className="popular-post-widget-brief">
								<h6><Link to="/blog-details">Lorem ipsum dolor sit
									cing elit, sed do.</Link></h6>
								<div className="ltn__blog-meta">
									<ul>
									<li className="ltn__blog-date">
										<a href="#"><i className="far fa-calendar-alt" />June 22, 2020</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
							</li>
						</ul>
						</div>*/}
						{/* Social Media Widget */}
						<div className="widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
						<div className="ltn__social-media-2">
							<ul>
							<li><a href="https://www.facebook.com/BlokHausRE/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="https://mobile.twitter.com/blokhausre" title="Twitter"><i className="fab fa-twitter" /></a></li>
							<li><a href="https://co.linkedin.com/company/blokhausre" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="https://www.instagram.com/BlokHausRE/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
						</div>
						{/* Banner Widget */}
						<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
						</div>
					</aside>
					</div>
				</div>
				</div>
			</div>
        }
}

export default PcDetails