import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import InquireForm from '../inquire';



class BannerPre extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/';
		let Name = this.props.Name;
		let imgBanner = this.props.BannerC;
		let Description = this.props.Description;
		let Sq = this.props.size;
		let Location = this.props.Loc;
		let price = this.props.price;
		let videoc = this.props.video;
		let localVideo = this.props.localvid;
    return  <div className="ltn__slider-area ltn__slider-4 position-relative  ">
			  <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active ltn__primary-bg section-bg-2">
			  <video autoPlay muted loop id="myVideo">
					<source src={publicUrl+localVideo} type="video/mp4" />
				</video>

			    {/* ltn__slide-item */}<a data-bs-toggle="collapse" data-bs-target="#Dataproject" href="#Dataproject">
			    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl+"data/Banners/"+imgBanner}>
				  <div className="ltn__slide-item-inner  text-left deactive-banner-pre">  
			        <div className="container">
			          <div className="row" id="Dataproject">
			            <div className="col-lg-6 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  <h1 className="slide-title animated" >{Name}</h1>
			                  <div className="slide-brief animated">
			                    <p>{Description}</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                    <a title="inquire" data-bs-toggle="modal" data-bs-target="#inquire" className="theme-btn-1 btn btn-effect-1">Inquire</a>
								<a className="ltn__video-play-btn bg-white" href={videoc} data-rel="lightcase">
			                        <i className="icon-play  ltn__secondary-color" />
			                      </a>
							  </div>
			                </div>
			              </div>
			            </div>
						<div className="col-lg-6 align-self-center text-end">
						<div className="widget-2 ltn__menu-widget ltn__menu-widget-2 text-uppercase">
						<ul className="go-top">
							<li> {Location} <i className="fa fa-location-dot"/> </li>
							<li>{Sq} Sqft  <i className="fa fa-building " /> </li>
							<li> FROM {price} <i className="fa fa-dollar" /></li>
						</ul>
						</div>
						{/*
						<div className="row ltn__custom-gutter mt-50 mb-20">
                        <div className="col-xl-6 col-sm-6 col-12 go-top">

                            <div className="ltn__feature-item text-center">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-google-docs" /></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h4><Link to="/service">Brochure</Link></h4>
                            </div>
                            </div>

                        </div>
                        <div className="col-xl-6 col-sm-6 col-12">
							<div className="ltn__feature-item  text-center active">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-apartment-1" /></span>
                            </div>
                            <div className="ltn__feature-info go-top">
                                <h4><a>Floorplans</a></h4>
                            </div>
                            </div>
                        </div>
                        </div>
			*/}
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
				</a>	
			  </div>
			  <div className="ltn__modal-area ltn__quick-view-modal-area">
				<div className="modal fade" id="inquire" tabIndex={-1}>
					<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
							{/* <i class="fas fa-times"></i> */}
						</button>
						</div>
						<div className="modal-body">
						<InquireForm/>
						</div>
					</div>
					</div>
				</div>
			</div>
			</div>

        }
}

export default BannerPre