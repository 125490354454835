import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TeamHome from './team-home';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<TeamHome />
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h1 className="section-title">About 
							<span> Juan Alvarez</span></h1>
						<p>Licensed Real Estate Broker Juan M. Alvarez is uniquely qualified, with unmatched experience and education:</p>
						</div>
						<ul>
				                <li>Master of Science in International Real Estate (MSIRE)</li>
				                <li>Master of Science in Finance (MSF)</li>
				                <li>Fintech-Certified from Harvard University</li>
				                <li>Over 15 years of experience brokering luxury residential and commercial real estate</li>
				                <li>Over 11 years of experience working with top Miami and New York brokerages</li>
				                <li>Lead transactions for a total volume of over $400M</li>
				              </ul>
						<div className="btn-wrapper animated">
						<a href="https://api.whatsapp.com/send/?phone=13057106804&text&type=phone_number&app_absent=0" className="theme-btn-1 btn btn-effect-1">Chat With Juan</a>
						</div>
					</div>
					</div>
				</div>
				<p>His involvement with the real estate faculty at Florida International University where he graduated with honors, made him eligible to become a member of the Real Estate Development Committee of the Bain Real Estate Council (BREC) at the Hollo School of Real Estate.

His understanding of local, global economics and financial trends makes him a unique consultant in real estate investments. Juan has helped both US residents and foreign investors achieve their goals when buying their luxury dream home or reaching their investment goals in record time to satisfaction. Juan has experience brokering high-end luxury residential properties as well as commercial properties of different nature such as multifamily buildings, strip malls, office buildings and triple net (NNN). He is highly skilled in analyzing the market with the right approach each time, using research and data that gives his clients the confidence that only an expert in the business with a proven track record of successful negotiations and closings can provide.</p>
				</div>
			</div>
        }
}

export default AboutV4