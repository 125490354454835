import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class SliderPC extends Component {

	constructor(props) {
		super(props);
	  }

    render() {

	let images = []
	if (this.props.images){images =this.props.images};
    return <div className="ltn__img-slider-area mb-90">
				<div className="container-fluid">
				<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href={process.env.PUBLIC_URL+images[0]} data-rel="lightcase:myCollection">
						<img src={process.env.PUBLIC_URL+images[0]} alt="Image" />
						</a>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href={process.env.PUBLIC_URL+images[1]} data-rel="lightcase:myCollection">
						<img src={process.env.PUBLIC_URL+images[1]} alt="Image" />
						</a>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href={process.env.PUBLIC_URL+images[2]} data-rel="lightcase:myCollection">
						<img src={process.env.PUBLIC_URL+images[2]} alt="Image" />
						</a>
					</div>
					</div> 	
				</div>
				</div>
			</div>
        }
}

export default SliderPC