import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ApartmentV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
	let CustomClass = this.props.CustomClass ? this.props.CustomClass : ''

    return <div className={"ltn__apartments-plan-area pt-50 pb-50 "+CustomClass}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12 ltn__car-dealer-form-tab">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>
			          <h1 className="section-title">Explore the Neighborhoods</h1>
			        </div>
			        <div className="ltn__tab-menu ltn__tab-menu ltn__tab-menu-top-right-- text-uppercase-- text-center">
			          <div className="nav">
			            <a data-bs-toggle="tab" href="#liton_tab_3_1"><i className="fas fa-building" />Condos</a>
			            <a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2"><i className="fas fa-home" />Single Family Homes</a>
			          </div>
			        </div>
			        <div className="tab-content">
			          <div className="tab-pane fade" id="liton_tab_3_1">
			            <div className="ltn__apartments-tab-content-inner">
						<div className="row">
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Miamibeach.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/miami-beach"> Miami Beach </Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Balharbour.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/bal-harbour">  Bal Harbour</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Brickell.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/brickell">  Brickell</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/SunnyIsles.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/sunny-isles">  Sunny Isles</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Edgewater.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/edgewater">  Edgewater</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Hollywood.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/condo/hollywood">  Hollywood</Link></h3>
						</div>
					</div>
					</div>
					</div>	
			            </div>
			          </div>
			          <div className="tab-pane fade active show" id="liton_tab_3_2">
			            <div className="ltn__product-tab-content-inner">
						<div className="row">
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Miamibeach.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/miami-beach"> Miami Beach </Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Balharbour.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/bal-harbour">  Bal Harbour</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/CoconutGrove.jpg"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/coconut-grove">  Coconut Grove</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Downtown.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/hollywood">  Hollywood</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Surfside.jpg"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/surfside">  Surfside</Link></h3>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= {publicUrl+"assets/img/neighborhoods/Edgewater.png"} >                        
						<div className="ltn__banner-info">
						<h3><Link to="/single-family-home/edgewater">  Edgewater</Link></h3>
						</div>
					</div>
					</div>
					</div>	
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default ApartmentV1