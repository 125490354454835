import React, { Component } from 'react';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<a href="mailto: info@blokhausre.com">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" /></a>
						</div>
						<h3>Email Address</h3>
						<a href="mailto: info@blokhausre.com"><p>info@blokhausre.com<br/> sales@blokhausre.com</p> </a>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
							<a href="tel:+1 (786) 536-7328">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon Image" /></a>
						</div><a href="tel:+1 (786) 536-7328">
						<h3>Phone Number</h3>
						<p>+1(305)710-6804 <br /> +1(786)536-7328</p></a>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
							<a href="https://goo.gl/maps/kUTZEMAiVzzLys9a8">
						<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon Image" /></a>
						</div>
						<h3>Office Address</h3>
						<a href="https://goo.gl/maps/kUTZEMAiVzzLys9a8">
						<p>1680 Michigan Ave, PH-5 <br />
						Miami Beach, FL 33139, EE. UU</p>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo