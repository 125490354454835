import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class TopBar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
		<div>
          <div className="ltn__header-top-area section-bg-6">
			<div className="container">
				<div className="row">
				<div className="col-md-7">
					<div className="ltn__top-bar-menu">
					<ul>
						<li><a href="mailto:info@blokhausre.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail" /> info@blokhausre.com</a></li>
						<li><a href="locations.html"><i className="icon-placeholder" /> 1680 Michigan Ave PH-5</a></li>
					</ul>
					</div>
				</div>
				<div className="col-md-5">
					<div className="top-bar-right text-end">
					<div className="ltn__top-bar-menu">
						<ul>
						<li>
							{/* ltn__social-media */}
							<div className="ltn__social-media">
							<ul>
								<li><a href="https://www.facebook.com/BlokHausRE/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
								<li><a href="https://mobile.twitter.com/blokhausre" title="Twitter"><i className="fab fa-twitter" /></a></li>
								<li><a href="https://co.linkedin.com/company/blokhausre" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
								<li><a href="https://www.instagram.com/BlokHausRE/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
							</div>
						</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
        )
    }
}


export default TopBar